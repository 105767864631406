<template>
  <div class="mt-3">
    <button class="btn btn-outline-primary" @click="addClient">Создать</button>
    <div v-if="spinner" class="spinner-border text-primary"></div>
  </div>

  <div class="list-group" v-for="item in clientsJson" :key="item._id">
    <div
      class="
        list-group-item list-group-item-action
        border border-primary
        mt-3
        gradient
      "
      :id="item._id"
    >
      <div class="row">
        <div class="col-sm">
          <div class="row"><small class="text-muted">Id</small></div>
          <div class="row">
            <h5 class="mb-1 h5 border-0 text-muted">
              {{ item._id }}
            </h5>
          </div>
        </div>
        <div class="col-sm">
          <div class="row"><small class="text-muted">Наименование</small></div>
          <div class="row">
            <input
              v-model="item.name"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            />
          </div>
        </div>
        <div class="col-sm">
          <div class="row"><small class="text-muted">Номер</small></div>
          <div class="row">
            <input
              v-model="item.number"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Ссылка клиента -->
        <div class="col-sm">
          <small class="text-muted">Ссылка клиента</small>
          <h6 class="mb-1 h5 border-0">
            {{ getRef(item) }}
          </h6>
        </div>

        <!-- guid1C -->
        <div class="col-sm">
          <div class="row"><small class="text-muted">guid1C</small></div>
          <div class="row">
            <input
              v-model="item.guid1C"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            />
          </div>
        </div>
      </div>

      <div>
        <small class="text-muted">Токены</small>
        <h6
          class="mb-1 h5 border-0"
          v-for="(itemToken, index) in item.tokens"
          :key="index"
        >
          {{ itemToken }}
        </h6>
      </div>
      <button
        v-if="item.modified"
        class="btn btn-warning"
        @click="saveClient(item)"
      >
        Сохранить
      </button>
      <button class="btn btn-outline-primary ms-3" @click="generateRef(item)">
        Новая ссылка
      </button>
      <div v-if="spinner" class="spinner-border text-primary"></div>
    </div>
  </div>

  <!-- <pre>
    {{ clientsJson }}
  </pre> -->
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup() {
    let clientsJson = ref([]);
    const spinner = ref(false);

    const loadClients = async () => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/getClient", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        clientsJson.value = await res.json();
      } catch (err) {
        console.log(err);
      }
    };

    const saveClient = async (item) => {
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      try {
        const res = await fetch(process.env.VUE_APP_API_URL + "/saveClient", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Basic " + btoa(login + ":" + password),
          },
          body: JSON.stringify(item),
        });
        const resJson = await res.json();
        if (resJson._id == item._id) {
          item.modified = false;
          spinner.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const addClient = async () => {
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let newClient = {
        name: "Новый",
      };
      console.log(newClient);
      try {
        const res = await fetch(process.env.VUE_APP_API_URL + "/addClient", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Basic " + btoa(login + ":" + password),
          },
          body: JSON.stringify(newClient),
        });
        const resJson = await res.json();
        if (resJson._id) {
          await loadClients();
          const el = document.getElementById(resJson._id);
          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
            el.classList.remove("gradient-blue");
            el.classList.add("gradient-red");
            spinner.value = false;
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    const generateRef = (item) => {
      let length = 6;
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      item.clientRef = result;
      item.modified = true;
    };

    const getRef = (item) => {
      return window.location.origin + "/ref/" + item.clientRef;
    };

    onMounted(loadClients);

    return {
      saveClient,
      clientsJson,
      generateRef,
      spinner,
      addClient,
      getRef,
    };
  },
};
</script>

 <style>
.gradient {
  background: linear-gradient(
    90deg,
    #a8ffee38,
    #ffffff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-red {
  background: linear-gradient(
    90deg,
    #ffa8af38,
    #ffffff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>