export const getCurWeek = async () => {
  console.log("getCurWeek");
  let login = localStorage.getItem("login");
  let password = localStorage.getItem("password");
  let curWeek = ''
  try {
    let res = await fetch(process.env.VUE_APP_API_URL + "/getCurWeek", {
      method: "GET",
      headers: { Authorization: "Basic " + btoa(login + ":" + password) },
    });
    curWeek = await res.text();
  } catch (err) {
    console.log(err);
  }
  return curWeek
};

export const loadEmployees = async () => {
  let employeesJson = []

  let login = localStorage.getItem("login");
  let password = localStorage.getItem("password");

  try {
    let res = await fetch(process.env.VUE_APP_API_URL + "/getEmployees", {
      method: "GET",
      headers: { Authorization: "Basic " + btoa(login + ":" + password) },
    });
    employeesJson = await res.json();
  } catch (err) {
    console.log(err);
  }
  employeesJson.unshift({_id: "allEmployees", name: 'Все'})
  return employeesJson
};

export const getWorkTypes1C = async () => {
  console.log('getWorkTypes1C');
  let workTypes1CJson = []

  let login = localStorage.getItem("login");
  let password = localStorage.getItem("password");

  try {
    let res = await fetch(process.env.VUE_APP_API_URL + "/getWorkTypes1C", {
      method: "GET",
      headers: { Authorization: "Basic " + btoa(login + ":" + password) },
    });
    workTypes1CJson = await res.json();
  } catch (err) {
    console.log(err);
  }

  return workTypes1CJson
}

export const getCurEmployee = async () => {
  console.log("getCurEmployee");
  let login = localStorage.getItem("login");
  let password = localStorage.getItem("password");

  try {
    let res = await fetch(process.env.VUE_APP_API_URL + "/getCurEmployee", {
      method: "GET",
      headers: { Authorization: "Basic " + btoa(login + ":" + password) },
    });
    let curEmployee = await res.json();
    return curEmployee;
  } catch (err) {
    console.log(err);
  }
};

export const getContacts = async (findString) => {
  console.log("getContacts");
  let login = localStorage.getItem("login");
  let password = localStorage.getItem("password");

  let resContacts = []
  try {
    let res = await fetch(
      process.env.VUE_APP_API_URL + "/getContacts/" + findString,
      {
        method: "GET",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
        },
      }
    );
    resContacts = await res.json();
  } catch (err) {
    console.log(err);
  }

  return resContacts
}

export const getClientContacts = async (clientId) => {
  console.log("getClientContacts");
  let login = localStorage.getItem("login");
  let password = localStorage.getItem("password");

  let resContacts = []
  try {
    let res = await fetch(
      process.env.VUE_APP_API_URL + "/getClientContacts/" + clientId,
      {
        method: "GET",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
        },
      }
    );
    resContacts = await res.json();
  } catch (err) {
    console.log(err);
  }

  return resContacts
}

//Никита Chat
export const getCurDate = async () => {
  console.log("getCurDate");
  let login = localStorage.getItem("login");
  let password = localStorage.getItem("password");
  let curDate = ''
  try {
    let res = await fetch(process.env.VUE_APP_API_URL + "/getCurDate", {
      method: "GET",
      headers: { Authorization: "Basic " + btoa(login + ":" + password) },
    });
    curDate = await res.text();
  } catch (err) {
    console.log(err);
  }
  return curDate
}

export const getCurUser = async () => {
  console.log("getCurUser");
  let login = localStorage.getItem("login");
  let password = localStorage.getItem("password");

  try {
    let res = await fetch(process.env.VUE_APP_API_URL + "/getCurUser", {
      method: "GET",
      headers: { Authorization: "Basic " + btoa(login + ":" + password) },
    });
    let curUser = await res.json();
    return curUser;
  } catch (err) {
    console.log(err);
  }
};