<template>
  <div class="mx-3">
    <!-- Filter -->
    <div class="col">
      <!-- Period -->
      <div class="row">
        <!-- dateFrom -->
        <div class="col">
          <div class="row">
            <small class="text-muted">Начало периода</small>
          </div>
          <div class="row">
            <v-date-picker v-model="dateFrom" mode="date" :popover="{ visibility: 'focus' }" @click="onFilterChange()">
              <template v-slot="{ inputValue, inputEvents }">
                <input class="
                    px-2
                    border
                    rounded
                    focus:outline-none focus:border-blue-300
                  " :value="inputValue" v-on="inputEvents" style="
                    width: 100%;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    line-height: 2;
                  " placeholder="Дата" readonly />
              </template>
            </v-date-picker>
          </div>
        </div>

        <!-- dateTo -->
        <div class="col" v-if="!oneDay">
          <div class="row">
            <small class="text-muted">Конец периода</small>
          </div>
          <div class="row">
            <v-date-picker v-model="dateTo" mode="date" :popover="{ visibility: 'focus' }" @click="onFilterChange()">
              <template v-slot="{ inputValue, inputEvents }">
                <input class="
                    px-2
                    border
                    rounded
                    focus:outline-none focus:border-blue-300
                  " :value="inputValue" v-on="inputEvents" style="
                    width: 100%;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    line-height: 2;
                  " placeholder="Дата" readonly />
              </template>
            </v-date-picker>
          </div>
        </div>

        <!-- oneDay -->
        <div class="col">
          <div class="row">
            <small class="text-muted">Один день</small>
          </div>
          <div class="row">
            <input v-model="oneDay" style="width: 3rem; height: 1.5rem" class="form-check-input" type="checkbox"
              @change="onFilterChange()" />
          </div>
        </div>
      </div>

      <!-- Employee -->
      <div class="row mt-3">
        <div class="col-8">
          <Multiselect v-model="selectedEmployee" :options="employeesJson" :loading="false" :internal-search="false"
            valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
            :searchable="true" class="deliverer" style="min-height: 3rem" noOptionsText="Не найден"
            placeholder="Сотрудник" @select="onFilterChange()">
          </Multiselect>
        </div>

        <!-- <div class="col-3">
          <button class="btn btn-primary" @click="updateWorks">Обновить</button>
        </div> -->

        <!-- Spinner -->
        <div class="col-1">
          <div v-if="spinner" class="spinner-border text-primary"></div>
        </div>
      </div>
    </div>

    <!-- Отчеты -->

    <!-- Отчет общий по часам -->
    <div class="table-responsive pt-3">
      <table class="table table-striped">
        <thead class="table-warning">
          <tr>
            <th scope="col">Всего часов</th>
            <th scope="col">Разнесено часов</th>
            <th scope="col">Платных часов</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="statisticsData.workAll.all">
              {{ statisticsData.workAll.all.toFixed(1) }}
            </td>
            <td v-if="statisticsData.workAll.verified">
              {{ statisticsData.workAll.verified.toFixed(1) }}
            </td>
            <td v-if="statisticsData.workAll.paid">
              {{ statisticsData.workAll.paid.toFixed(1) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Аккордион -->
    <div class="accordion mt-3" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
            aria-expanded="true" aria-controls="collapseOne">
            Расшифровка
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <!-- Отчет по клиентам -->
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="table-warning">
                  <tr>
                    <th scope="col">Клиент</th>
                    <th scope="col">Всего часов</th>
                    <th scope="col">Разнесено часов</th>
                    <th scope="col">Платных часов</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(
                      statisticsRow, index
                    ) in statisticsData.workPerClient" :key="index">
                    <th scope="row">{{ index }}</th>
                    <td v-if="statisticsRow.all">
                      {{ statisticsRow.all.toFixed(1) }}
                    </td>
                    <td v-if="statisticsRow.verified">
                      {{ statisticsRow.verified.toFixed(1) }}
                    </td>
                    <td v-if="statisticsRow.paid">
                      {{ statisticsRow.paid.toFixed(1) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Отчет по видам работ -->
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="table-warning">
                  <tr>
                    <th scope="col">Тип работы</th>
                    <th scope="col">Всего часов</th>
                    <th scope="col">Разнесено часов</th>
                    <th scope="col">Платных часов</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(statisticsRow, index) in statisticsData.workPerType" :key="index">
                    <th scope="row">{{ index }}</th>
                    <td v-if="statisticsRow.all">
                      {{ statisticsRow.all.toFixed(1) }}
                    </td>
                    <td v-if="statisticsRow.verified">
                      {{ statisticsRow.verified.toFixed(1) }}
                    </td>
                    <td v-if="statisticsRow.paid">
                      {{ statisticsRow.paid.toFixed(1) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Список задач -->
    <div class="list-group">
      <div class="
          list-group-item list-group-item-action
          border border-primary
          shadow
          mt-3
        " v-for="(work, index) in worksFiltered" :key="index">
        <div v-bind:class="{
          'gradient-red': work.client && !work.verified,
          'gradient-green': work.client && work.verified,
        }">
          <div class="row">
            <!-- date -->
            <div class="col-4 px-1">
              <v-date-picker v-model="work.date" mode="date" :popover="{ visibility: 'focus' }"
                @click="work.changed = true">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="
                      px-2
                      border
                      rounded
                      focus:outline-none focus:border-blue-300
                    " :value="inputValue" v-on="inputEvents" style="
                      width: 100%;
                      padding-top: 2px;
                      padding-bottom: 2px;
                      line-height: 2;
                    " placeholder="Дата" @input="work.changed = true" readonly />
                </template>
              </v-date-picker>
            </div>
            <!-- work type -->
            <div class="col px-1">
              <Multiselect v-model="work.workType" :options="workTypesJson" :loading="false" :internal-search="false"
                valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
                :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Вид работы"
                @change="work.changed = true">
              </Multiselect>
            </div>
          </div>

          <!-- employee -->
          <div class="row mt-1" v-if="!selectedEmployee">
            <div class="col px-1">
              <Multiselect v-model="work.employee" :options="employeesJson" :loading="false" :internal-search="false"
                valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
                :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Сотрудник"
                @change="work.changed = true">
              </Multiselect>
            </div>
          </div>

          <!-- client -->
          <div class="row mt-1">
            <div class="col px-1">
              <Multiselect v-model="work.client" :options="clientsJson" :loading="false" :internal-search="false"
                valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
                :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Клиент"
                @select="getWorkContacts(work)">
              </Multiselect>
            </div>
          </div>

          <!-- contact -->
          <div class="row mt-1">
            <div class="col px-1">
              <Multiselect v-model="work.contact" v-if="work.contacts" :options="work.contacts" :loading="false"
                :internal-search="false" valueProp="_id" trackBy="fio" label="fio" :filterResults="true" :minChars="2"
                :resolveOnLoad="true" :searchable="true" style="min-height: 3rem" noOptionsText="Не найден"
                placeholder="Контакт" @change="work.changed = true">
              </Multiselect>
            </div>
          </div>

          <!-- workTypes1CJson -->
          <div class="row mt-1">
            <div class="col px-1">
              <Multiselect v-if="work.workType1C" v-model="work.workType1C._id" :options="workTypes1CJson"
                :loading="false" :internal-search="false" valueProp="_id" trackBy="name" label="name"
                :filterResults="true" :minChars="2" :resolveOnLoad="true" :searchable="true" style="min-height: 3rem"
                noOptionsText="Не найден" placeholder="Вид работы 1С" @change="work.changed = true">
              </Multiselect>
            </div>
          </div>

          <!-- hours and verified -->
          <div class="row mt-1">
            <div class="col px-1">
              <input v-model="work.hours" type="number" class="form-control" placeholder="Количество часов"
                @input="work.changed = true" />
            </div>
            <div class="col px-1">
              <small class="text-muted">Разнесено</small>
              <input v-model="work.verified" style="width: 3rem; height: 1.5rem" class="form-check-input"
                type="checkbox"/>
            </div>
            <div class="col px-1">
              <button class="btn btn-outline-success" v-if="
                !work.verified &&
                work.workType1C &&
                work.client &&
                work.contact
              " @click="sendWorkTo1C(work)">
                Разнести
              </button>
            </div>
          </div>

          <!-- comment -->
          <div class="row mt-1">
            <div class="col px-1">
              <input v-model="work.comment" type="text" class="form-control" placeholder="Комментарий"
                @input="work.changed = true" />
            </div>
          </div>

          <!-- buttons -->
          <div class="row mt-1">
            <!-- button save work -->
            <div class="col">
              <button class="btn btn-warning" v-if="work.changed" @click="saveWork(work)">
                Сохранить
              </button>
            </div>

            <div v-if="work.spinner" class="spinner-border text-primary"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="btn btn-outline-primary fixed_button" @click="addWork()">
    Создать
  </button>

  <!-- <pre>
    {{ worksJson }}
  </pre> -->
</template>

<script>
import { ref, onMounted, computed } from "vue";
import "v-calendar/dist/style.css";
import Multiselect from "@vueform/multiselect";
import * as common from "./common.js";

export default {
  components: {
    Multiselect,
  },
  setup() {
    const dateFrom = ref(new Date().setHours(0, 0, 0, 0));
    const dateTo = ref(new Date().setHours(23, 59, 59, 999));
    const employeesJson = ref([]);
    const contactsJson = ref([]);
    const selectedEmployee = ref("");
    const workTypesJson = ref([]);
    const workTypes1CJson = ref([]);
    const worksJson = ref([]);
    const oneDay = ref(true);
    let curEmployee = undefined;

    const init = async () => {
      try {
        let login = localStorage.getItem("login");
        let password = localStorage.getItem("password");
        let res = await fetch(process.env.VUE_APP_API_URL + "/getEmployees", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        employeesJson.value = await res.json();
      } catch (err) {
        console.log(err);
      }
      loadClients();
      loadWorkTypes();
      workTypes1CJson.value = await common.getWorkTypes1C();
      curEmployee = await common.getCurEmployee();
      if (curEmployee) {
        selectedEmployee.value = curEmployee._id;
        updateWorks();
      }
    };

    let clientsJson = ref([]);
    const spinner = ref(false);
    const checkIn1CSpinner = ref(false);

    const worksFiltered = computed(() => {
      let result = worksJson.value;
      if (selectedEmployee.value) {
        result = result.filter(
          (work) => work.employee == selectedEmployee.value || !("_id" in work)
        );
      }
      //console.log("selectedEmployee", result);

      if (dateFrom.value) {
        result = result.filter(
          (work) =>
            new Date(work.date) >= dateFrom.value ||
            !("_id" in work) ||
            work.changed
        );

        if (oneDay.value) {
          result = result.filter(
            (work) =>
              new Date(work.date) <=
              new Date(dateFrom.value).setHours(23, 59, 59, 999) ||
              !("_id" in work) ||
              work.changed
          );
        }
      }
      //console.log("dateFrom", result);

      if (!oneDay.value && dateTo.value) {
        result = result.filter(
          (work) =>
            new Date(work.date) <= dateTo.value ||
            !("_id" in work) ||
            work.changed
        );
      }

      for (const work of result) {
        if (!work.workType1C && work.contact && work.contact) {
          work.workType1C = {}
        }
      }


      return result;
    });

    const statisticsData = computed(() => {
      let workPerClient = {};
      let workHours = 0
      for (const work of worksFiltered.value) {
        workHours = work.hours
        if (workHours == "") workHours = 0;

        let clientName = "";
        for (const client of clientsJson.value) {
          if (client._id == work.client) clientName = client.name;
        }

        if (!workPerClient[clientName]) {
          workPerClient[clientName] = {};
        }

        if (!workPerClient[clientName]["verified"]) {
          workPerClient[clientName]["verified"] = 0;
        }

        if (!workPerClient[clientName]["all"]) {
          workPerClient[clientName]["all"] = 0;
        }

        if (!workPerClient[clientName]["paid"]) {
          workPerClient[clientName]["paid"] = 0;
        }

        if (work.verified) {
          workPerClient[clientName].verified += workHours;
        }

        if (work.workType1C) {
          if (work.workType1C.paid) {
            workPerClient[clientName].paid += workHours;
          }
        }

        workPerClient[clientName].all += workHours;
      }

      let workPerType = {};
      for (const work of worksFiltered.value) {
        workHours = work.hours
        if (workHours == "") workHours = 0;

        let workTypeName = "";
        for (const workType of workTypesJson.value) {
          if (workType._id == work.workType) workTypeName = workType.name;
        }

        if (!workPerType[workTypeName]) {
          workPerType[workTypeName] = {};
        }

        if (!workPerType[workTypeName]["verified"]) {
          workPerType[workTypeName]["verified"] = 0;
        }

        if (!workPerType[workTypeName]["all"]) {
          workPerType[workTypeName]["all"] = 0;
        }

        if (!workPerType[workTypeName]["paid"]) {
          workPerType[workTypeName]["paid"] = 0;
        }

        if (work.verified) {
          workPerType[workTypeName].verified += workHours;
        }

        if (work.workType1C) {
          if (work.workType1C.paid) {
            workPerType[workTypeName].paid += workHours;
          }
        }

        workPerType[workTypeName].all += workHours;
      }

      let workAll = {};
      for (const work of worksFiltered.value) {
        workHours = work.hours
        if (workHours == "") workHours = 0;

        if (!workAll["verified"]) {
          workAll["verified"] = 0;
        }

        if (!workAll["all"]) {
          workAll["all"] = 0;
        }

        if (!workAll["paid"]) {
          workAll["paid"] = 0;
        }

        if (work.verified) {
          workAll.verified += workHours;
        }

        if (work.workType1C) {
          if (work.workType1C.paid) {
            workAll.paid += workHours;
          }
        }

        workAll.all += workHours;
      }

      let result = {};
      result.workPerClient = workPerClient;
      result.workPerType = workPerType;
      result.workAll = workAll;
      return result;
    });

    const addWork = async () => {
      console.log("addWork");
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      let newWork = {
        date: dateFrom.value,
        employee: selectedEmployee.value ? selectedEmployee.value : null,
        verified: false,
        hours: 0,
      };
      let res = await fetch(process.env.VUE_APP_API_URL + "/addWork", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(newWork),
      });

      const resJson = await res.json();
      if (resJson._id) {
        await updateWorks();
        const el = document.getElementById(resJson._id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
          el.classList.remove("gradient-blue");
          el.classList.add("gradient-red");
          spinner.value = false;
        }
      }
    };

    const saveWork = async (item) => {
      console.log("saveWork");
      item.spinner = true;

      if (item.workType1C) {
        if (item.workType1C._id == null) item.workType1C = null
      }
      
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      let res = await fetch(process.env.VUE_APP_API_URL + "/saveWork", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(item),
      });
      let resJson = await res.json();
      if (resJson._id == item._id) {
        item.spinner = false;
        item.changed = false;
      }
    };

    const loadClients = async () => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/getClient", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        clientsJson.value = await res.json();
      } catch (err) {
        console.log(err);
      }
    };

    const loadWorkTypes = async () => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/getWorkTypes", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        workTypesJson.value = await res.json();
        //console.log(workTypesJson.value);
      } catch (err) {
        console.log(err);
      }
    };

    const getWorkContacts = async (work) => {
      console.log("getClientContacts");
      if (!work.contacts && work.client) {
        work.contacts = [];
        work.contacts = await common.getClientContacts(work.client);
        if (work.contacts.length == 0) work.contacts = undefined;
      }
    };

    const getAllWorkContacts = async () => {
      console.log("getAllWorkContacts");
      for (const work of worksJson.value) {
        if (!work.contacts && work.contact) {
          work.contacts = await common.getClientContacts(work.client);
        }
      }
    };

    const checkIn1C = async (workDate) => {
      console.log("checkIn1C");
      checkIn1CSpinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      let workParams = {
        date: workDate,
        employeeId: selectedEmployee.value,
      };

      let res = await fetch(process.env.VUE_APP_API_URL + "/checkIn1C", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(workParams),
      });
      let resJson = await res.json();
      console.log(resJson);
      await updateWorks();
      checkIn1CSpinner.value = false;
    };

    const sendWorkTo1C = async (item) => {
      console.log("sendWorkTo1C");

      item.spinner = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      await fetch(process.env.VUE_APP_API_URL + "/sendWorkTo1C", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(item),
      });
      item.spinner = false;
      checkIn1C(item.date);
    };

    const updateWorks = async () => {
      console.log("updateWorks");
      spinner.value = true;

      let startPeriod = new Date(dateFrom.value).getTime();
      let endPeriod = new Date(dateTo.value).getTime();
      if (!selectedEmployee.value) {
        spinner.value = false;
        return;
      }
      let employeeId = selectedEmployee.value;

      let getParams =
        "?startPeriod=" +
        startPeriod +
        "&endPeriod=" +
        endPeriod +
        "&employeeId=" +
        employeeId;

      let fetchRef =
        process.env.VUE_APP_API_URL + "/getWorkFiltered" + getParams;

      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      try {
        let res = await fetch(fetchRef, {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        let resJson = await res.json();
        worksJson.value = resJson;
        getAllWorkContacts();
      } catch (error) {
        console.log(error);
      }
      spinner.value = false;
    };

    const onFilterChange = () => {
      updateWorks();
    };

    onMounted(init);

    return {
      clientsJson,
      spinner,
      dateFrom,
      dateTo,
      employeesJson,
      selectedEmployee,
      workTypesJson,
      addWork,
      worksJson,
      saveWork,
      worksFiltered,
      oneDay,
      statisticsData,
      workTypes1CJson,
      checkIn1C,
      checkIn1CSpinner,
      contactsJson,
      getWorkContacts,
      updateWorks,
      sendWorkTo1C,
      onFilterChange,
    };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css">
</style>

<style>
.gradient {
  background: linear-gradient(90deg,
      #a8ffee38,
      #ffffff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-red {
  background: linear-gradient(90deg,
      #ffa8af38,
      #ffffff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.fixed_button {
  position: fixed;
  /*задаём тип позиции, в нашем случае - фиксированная*/
  bottom: 50px;
  right: 50px;
  z-index: 999;
}

.fixed_button_2 {
  position: fixed;
  /*задаём тип позиции, в нашем случае - фиксированная*/
  bottom: 50px;
  left: 50px;
  z-index: 999;
}

.transparent {
  background-color: transparent;
}
</style>