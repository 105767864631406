<template>
  <div class="row" style="background-color: #eee">
    <!-- Contact list -->
    <div
      class="overflow-auto col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0"
      style="height: 500px"
      
    >
      <div class="card" v-for="item in chatRooms" :key="item._id">
        <div class="card-body" :id="item._id">
          <ul class="list-unstyled mb-0">
            <li class="p-2 border-bottom" style="background-color: #eee">
              <a href="#!" class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-8.webp"
                    alt="avatar"
                    class="
                      rounded-circle
                      d-flex
                      align-self-center
                      me-3
                      shadow-1-strong
                    "
                    width="60"
                  />
                  <div class="pt-1">
                    <p class="fw-bold mb-0" v-if="curUser">{{ item.users[0].name }}</p>
                    <p class="small text-muted">{{ item.lastMessage }}</p>
                  </div>
                </div>
                <div class="pt-1">
                  <p class="small text-muted mb-1">{{ formatTime(item) }}</p>
                  <span class="badge bg-danger float-end">1</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Chat -->
    <div class="overflow-auto col-md-6 col-lg-7 col-xl-8" style="height: 500px">
      <ul class="list-unstyled">
        <li class="d-flex justify-content-between mb-4">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
            alt="avatar"
            class="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="60"
          />
          <div class="card">
            <div class="card-header d-flex justify-content-between p-3">
              <p class="fw-bold mb-0">Brad Pitt</p>
              <p class="text-muted small mb-0">
                <i class="far fa-clock"></i> 12 mins ago
              </p>
            </div>
            <div class="card-body">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </li>
        <li class="d-flex justify-content-between mb-4">
          <div class="card w-100">
            <div class="card-header d-flex justify-content-between p-3">
              <p class="fw-bold mb-0">Lara Croft</p>
              <p class="text-muted small mb-0">
                <i class="far fa-clock"></i> 13 mins ago
              </p>
            </div>
            <div class="card-body">
              <p class="mb-0">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium.
              </p>
            </div>
          </div>
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp"
            alt="avatar"
            class="rounded-circle d-flex align-self-start ms-3 shadow-1-strong"
            width="60"
          />
        </li>
        <li class="d-flex justify-content-between mb-4">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
            alt="avatar"
            class="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="60"
          />
          <div class="card">
            <div class="card-header d-flex justify-content-between p-3">
              <p class="fw-bold mb-0">Brad Pitt</p>
              <p class="text-muted small mb-0">
                <i class="far fa-clock"></i> 10 mins ago
              </p>
            </div>
            <div class="card-body">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </li>
        <li class="d-flex justify-content-between mb-4">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
            alt="avatar"
            class="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="60"
          />
          <div class="card">
            <div class="card-header d-flex justify-content-between p-3">
              <p class="fw-bold mb-0">Brad Pitt</p>
              <p class="text-muted small mb-0">
                <i class="far fa-clock"></i> 10 mins ago
              </p>
            </div>
            <div class="card-body">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </li>
        <li class="d-flex justify-content-between mb-4">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
            alt="avatar"
            class="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="60"
          />
          <div class="card">
            <div class="card-header d-flex justify-content-between p-3">
              <p class="fw-bold mb-0">Brad Pitt</p>
              <p class="text-muted small mb-0">
                <i class="far fa-clock"></i> 10 mins ago
              </p>
            </div>
            <div class="card-body">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </li>
        <li class="d-flex justify-content-between mb-4">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
            alt="avatar"
            class="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="60"
          />
          <div class="card">
            <div class="card-header d-flex justify-content-between p-3">
              <p class="fw-bold mb-0">Brad Pitt</p>
              <p class="text-muted small mb-0">
                <i class="far fa-clock"></i> 10 mins ago
              </p>
            </div>
            <div class="card-body">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </li>
        <li class="d-flex justify-content-between mb-4">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
            alt="avatar"
            class="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="60"
          />
          <div class="card">
            <div class="card-header d-flex justify-content-between p-3">
              <p class="fw-bold mb-0">Brad Pitt</p>
              <p class="text-muted small mb-0">
                <i class="far fa-clock"></i> 10 mins ago
              </p>
            </div>
            <div class="card-body">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </li>
        <li class="bg-white mb-3">
          <div class="form-outline">
            <textarea
              class="form-control"
              id="textAreaExample2"
              rows="4"
            ></textarea>
          </div>
        </li>
        <button type="button" class="btn btn-info btn-rounded float-end" @click="postMessage">
          Send
        </button> 
      </ul>
    </div>

    <!-- <pre>
      {{ curUser }}
    </pre> -->
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import * as common from "./common.js";


export default {
  setup() {
    const employeesJson = ref([]);
    let usersJson = ref([]);
    let chatRooms = ref([]);
    let dateCur = ref('');
    let curUser = ref('');

    const getUsers = async () => {
      console.log("/getUsers");
      let res = await fetch(process.env.VUE_APP_API_URL + "/getUsers", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("login") +
                ":" +
                localStorage.getItem("password")
            ),
        },
      });
      console.log(res.status);
      if (res.status == 200) {
        usersJson.value = await res.json();
      }
    };
    
    onMounted(async () => {
      console.log("onMounted");
      employeesJson.value = await common.loadEmployees();
      dateCur.value = await common.getCurDate();
      curUser.value = await common.getCurUser();
      await getUsers();
      await getChatRooms();
      await formatTime();
    });

    const currentDate = (date) => {
      if (dateCur.value == Date(date)) return true;
      return false;
    };

    //сделать чек юзера с юзерами чатрума

    const formatTime = (item) => {
      let msgDate = new Date(item.lastMessageDate)
      console.log(msgDate)
      let ISOToDate = new Date(dateCur.value)
      let diff = ISOToDate - msgDate
      console.log(diff)
      
      if( 36e5 < diff > 60e3) return Math.floor(diff / 60e3) + ' mins ago'
      else if (diff >= 36e5 ) {return Math.floor(diff / 36e5) + ' hours ago'}
      else {return Math.floor(diff / 1e3) + ' secs ago'}
    };

    const getChatRooms = async () => {
      let res = await fetch(process.env.VUE_APP_API_URL + "/getChatRooms", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("login") +
                ":" +
                localStorage.getItem("password")
            ),
        },
      });
      console.log(res.status);
      if(res.status == 200){
        chatRooms.value = await res.json();
        
      }
      
    };
    /*
    const postMessage = async () => {

    };
    */
    return {
      employeesJson,
      usersJson,
      getUsers,
      getChatRooms,
      chatRooms,
      dateCur,
      currentDate,
      formatTime,
      curUser,
    };
  },
};
</script>
