<template>
  <footer class="py-3 my-4">
    <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      <li class="nav-item">
        <a
          v-if="!isNativePlatform"
          href="https://play.google.com/store/apps/details?id=ru.pcs.enterprise"
          ><img
            src="/google-play-badge.png"
            alt="google-play-badge"
            width="150"
        /></a>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link px-2 text-muted">enterprise {{version}}</a>
      </li>
    </ul>
    <p class="text-center text-muted">© 1997-2022 Компьютер Сервис</p>
  </footer>
</template>

<script>
import { ref } from "vue";
import { Capacitor } from "@capacitor/core";

export default {
  setup() {
    const isNativePlatform = ref(Capacitor.isNativePlatform());
    const version = ref("")
    
    var pjson = require("../../package.json");
    version.value = pjson.version

    return {
      isNativePlatform,
      version
    };
  },
};
</script>