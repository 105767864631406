<template>
  <div id="nav">
    <NavBar />
  </div>
  <router-view />
  <Footer />
</template>



<script>
import { onMounted, ref } from "vue";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { Toast } from "@capacitor/toast";
import { useRouter } from "vue-router";
import { App } from "@capacitor/app";

export default {
  components: {
    NavBar,
    Footer,
  },
  setup() {
    const router = useRouter();
    const isNativePlatform = ref(Capacitor.isNativePlatform());

    const registerEmployeeToken = async (tokenValue) => {
      let pushToken = { token: tokenValue };
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(process.env.VUE_APP_API_URL + "/updateToken", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(pushToken),
      });
      let resJson = await res.json();
      //alert(resJson.result);
      await Toast.show({
        text: resJson.result,
      });
    };

    const registerClientToken = async (tokenValue, clientRef) => {
      let tokenRegData = { token: tokenValue, clientRef: clientRef };
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/updateClientToken",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },

          body: JSON.stringify(tokenRegData),
        }
      );
      let resJson = await res.json();
      await Toast.show({
        text: resJson.result,
      });
    };

    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener("registration", async (token) => {
        if (localStorage.getItem("clientRef")) {
          await registerClientToken(
            token.value,
            localStorage.getItem("clientRef")
          );
          return;
        }

        if (localStorage.getItem("login")) {
          await registerEmployeeToken(token.value);
          return;
        }
      });

      PushNotifications.addListener("registrationError", (error) => {
        alert("Error on registration: " + JSON.stringify(error));
      });

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          //alert("Push action performed: " + notification.notification.data.ref);
          //window.location.href = notification.notification.data.ref;
          router.push(notification.notification.data.ref);
          // console.log(notification);
          // router.push('/task/61a0cc3d19f6e8617eb32c59')
        }
      );

      App.addListener("appUrlOpen", (event) => {
        //alert("App opened with URL:" + data);
        const slug = event.url.split(".ru").pop();
        // We only push to the route if there is a slug present
        if (slug) {
          router.push(slug);
        }
      });
    }

    onMounted(() => {
      console.log("onMounted");

      if (Capacitor.isNativePlatform()) {
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === "granted") {
            PushNotifications.register();
          }
        });
      }
    });

    return {
      isNativePlatform,
    };
  },
};
</script>
