<template>
  <div class="mx-3">
    <!-- Организация -->
    <div class="row mt-3">
      <div class="col">
        <Multiselect v-model="currentClient" :options="clientsJson" :loading="false" :internal-search="false"
          valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
          :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Организация" class="">
        </Multiselect>
      </div>
    </div>

    <!-- Поиск, Фильтр, Очистить -->
    <div class="row mt-1">
      <div class="col">
        <div class="input-group">
          <input v-model="searchText" class="form-control" placeholder="Поиск" @change="filterProjects()" />
          <button class="btn btn-info" @click="filterProjects()">
            Фильтр
          </button>
          <button class="btn btn-secondary" @click="clearSearchText()">
            Очистить
          </button>
        </div>
      </div>
    </div>

    <!-- Добавить -->
    <div class="row mt-1">
      <div class="col-4">
        <button class="btn btn-outline-success" @click="addProject()">
          Добавить
        </button>
      </div>
    </div>

    <!-- Список проектов -->
    <div v-if="!searchSpinner">
      <div v-for="project in projectsFiltered" :key="project._id">
        <div class="row m-2 shadow">
          <!-- Кнопки Изменить Сохранить -->
          <div class="col-sm-2">
            <div class="btn-group">
              <button class="btn btn-light" @click="project.edit = project.edit ? false : true">
                <font-awesome-icon icon="edit" />
              </button>
              <button class="btn btn-light" @click="saveProject(project)">
                <font-awesome-icon icon="save" />
              </button>
            </div>
          </div>

          <!-- Клиент проекта -->
          <div class="col-sm-4">
            <Multiselect v-model="project.client" :options="clientsJson" :loading="false" :internal-search="false"
              valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
              :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Организация" :limit="5"
              :disabled="!project.edit" class="fs-5">
            </Multiselect>
          </div>

          <!-- Проект наименование и ссылка -->
          <div class="col-sm">
            <div class="row mb-1">
              <div class="col-sm">
                <input v-if="project.edit" v-model="project.label" class="form-control" />
                <span v-else class="fs-4">{{ project.label }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <input v-if="project.edit" v-model="project.link" class="form-control" />
                <a v-else class="fs-6" target="_blank" :href="project.link" style="word-wrap: break-word;">{{
                    project.link
                }}</a>
              </div>
            </div>
          </div>

          <!-- Результат поиска -->
          <div v-if="searchText.length >= 3" class="col-sm">
            <span>{{ project.searchTextBefore }}</span>
            <span class="bg-warning">{{ project.searchTextCenter }}</span>
            <span>{{ project.searchTextAfter }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 d-flex justify-content-center" v-if="searchSpinner">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import Multiselect from "@vueform/multiselect";

export default {
  components: { Multiselect },
  setup() {
    const searchText = ref("");
    let projectsJson = ref([]);
    let clientsJson = ref([]);
    const spinner = ref(false);
    const projectFilter = ref("withProjects");
    const currentClient = ref();

    const loadProjects = async () => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/project", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        let projects = await res.json();
        projects = projects.sort((a, b) => {
          let aClientIndex = clientsJson.value.findIndex(
            (item) => item._id == a.client
          );
          let aClientName =
            aClientIndex >= 0 ? clientsJson.value[aClientIndex].name : "";
          let bClientIndex = clientsJson.value.findIndex(
            (item) => item._id == b.client
          );
          let bClientName =
            bClientIndex >= 0 ? clientsJson.value[bClientIndex].name : "";

          let result = 0;
          if (aClientName > bClientName) result = 1;
          if (aClientName < bClientName) result = -1;
          return result;
        });

        projectsJson.value = projects;
        // projectsJson.value = projects.reduce((r, a) => {
        //   // console.log("a", a);
        //   // console.log("r", r);
        //   r[a.client] = [...(r[a.client] || []), a];
        //   return r;
        // }, {});
      } catch (err) {
        console.log(err);
      }
    };

    const loadClients = async () => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/getClient", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        clientsJson.value = await res.json();
      } catch (err) {
        console.log(err);
      }
    };

    const asyncFilter = async (arr, predicate) => {
      const results = await Promise.all(arr.map(predicate));

      return arr.filter((_v, index) => results[index]);
    };

    watch(
      () => currentClient.value,
      () => {
        filterProjects();
      }
    );

    const filterProjects = async () => {
      searchSpinner.value = true;
      let result = projectsJson.value;

      if (currentClient.value) {
        result = result.filter(
          (project) => project.client == currentClient.value
        );
      }

      if (searchText.value.length >= 3) {
        result = await asyncFilter(result, async (project) => {
          let showProject = false;
          let projectString = JSON.stringify(project).toLowerCase();
          let searchIndex = projectString.indexOf(
            searchText.value.toLowerCase()
          );
          showProject = searchIndex >= 0;

          // let projectHTML = await fetch(project.link);
          // let projectText = await projectHTML.text();
          let projectText = ''

          if (
            project.link.includes("https://confluence.cs-develop.ru/view/") ||
            project.link.includes("https://develop.marco.az/view/")
          ) {
            let articlePath = project.link;
            articlePath = articlePath.replace(
              "https://confluence.cs-develop.ru/view/",
              ""
            );
            articlePath = articlePath.replace(
              "https://develop.marco.az/view/",
              ""
            );
            articlePath = articlePath.replace("/", "");
            if (articlePath.includes("#"))
              articlePath = articlePath.substring(0, articlePath.indexOf("#"));
            let res = await fetch(
              `https://confluence.cs-develop.ru/getArticle/${articlePath}`
            );
            let resJson = await res.json();
            projectText = resJson.text;
          }

          let position = projectText
            .toLowerCase()
            .search(searchText.value.toLowerCase());
          if (position >= 0) {
            let step = 40;
            let startPosition = position - step > 0 ? position - step : 0;
            let endPosition =
              position + step < projectText.length
                ? position + step
                : projectText.length;
            project.searchTextBefore = projectText.substring(
              startPosition,
              position
            );
            project.searchTextAfter = projectText.substring(
              position + searchText.value.length,
              endPosition
            );
            project.searchTextCenter = projectText.substring(
              position,
              position + searchText.value.length
            );
            console.log(project.searchTextCenter);
            // project.searchText = projectText.substring(startPosition, endPosition)
            // var regex = new RegExp(searchText.value,"ig")
            // project.searchText = project.searchText.replace(regex, `<span class='bg-warning'>${searchText.value}</span>`)
            // console.log(project.searchText);
            showProject = true;
          }

          return showProject;
        });
      }

      projectsFiltered.value = result;
      searchSpinner.value = false;
    };

    const clearSearchText = async () => {
      searchSpinner.value = true;
      searchText.value = "";
      projectsFiltered.value = projectsJson.value;
      searchSpinner.value = false;
    };

    const projectsFiltered = ref([]);

    const searchSpinner = ref(false);

    const clientsFiltered = computed(() => {
      let result = [];
      if (projectFilter.value == "all") {
        result = clientsJson.value;
      } else if (projectFilter.value == "withProjects") {
        result = clientsJson.value.filter(
          (item) => item._id in projectsJson.value
        );
      }
      return result;
    });

    const saveClient = async (item) => {
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      try {
        const res = await fetch(process.env.VUE_APP_API_URL + "/saveClient", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Basic " + btoa(login + ":" + password),
          },
          body: JSON.stringify(item),
        });
        const resJson = await res.json();
        if (resJson._id == item._id) {
          item.modified = false;
          spinner.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const addClient = async () => {
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let newClient = {
        name: "Новый",
      };
      console.log(newClient);
      try {
        const res = await fetch(process.env.VUE_APP_API_URL + "/addClient", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Basic " + btoa(login + ":" + password),
          },
          body: JSON.stringify(newClient),
        });
        const resJson = await res.json();
        if (resJson._id) {
          await loadClients();
          const el = document.getElementById(resJson._id);
          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
            el.classList.remove("gradient-blue");
            el.classList.add("gradient-red");
            spinner.value = false;
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    const generateRef = (item) => {
      let length = 6;
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      item.clientRef = result;
      item.modified = true;
    };

    const getRef = (item) => {
      return window.location.origin + "/ref/" + item.clientRef;
    };

    const addProject = () => {
      projectsJson.value.unshift({
        label: "",
        link: "",
        edit: true,
        client: null,
      });
    };

    const saveProject = async (item) => {
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      try {
        const res = await fetch(process.env.VUE_APP_API_URL + "/project", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Basic " + btoa(login + ":" + password),
          },
          body: JSON.stringify(item),
        });
        const resJson = await res.json();
        if ("_id" in resJson && resJson._id) {
          item._id = resJson._id;
          item.edit = false;
        }
        spinner.value = false;
      } catch (err) {
        console.log(err);
      }
    };

    const init = async () => {
      await loadClients();
      await loadProjects();
      await filterProjects();
    };

    onMounted(init);

    return {
      saveClient,
      projectsJson,
      saveProject,
      addProject,
      clientsJson,
      generateRef,
      spinner,
      addClient,
      getRef,
      projectFilter,
      clientsFiltered,
      searchText,
      projectsFiltered,
      searchSpinner,
      filterProjects,
      clearSearchText,
      currentClient,
    };
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(90deg,
      #a8ffee38,
      #ffffff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-red {
  background: linear-gradient(90deg,
      #ffa8af38,
      #ffffff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
