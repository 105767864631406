import { createApp } from 'vue'
import App from './App.vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import router from './router'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

library.add(faSave);
library.add(faPlus);
library.add(faEdit);

import VCalendar from 'v-calendar';


const app = createApp(App);
app.use(router).component("font-awesome-icon", FontAwesomeIcon).use(VCalendar, {}).mount('#app')