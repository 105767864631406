import { createRouter, createWebHistory } from 'vue-router'

import StartPage from '../views/StartPage.vue'

import TaskList from '../components/TaskList.vue'
import ClientList from '../components/ClientList.vue'
import EmployeeList from '../components/EmployeeList.vue'
import TaskReport from '../components/TaskReport.vue'
import UserList from '../components/UserList.vue'
import ProjectList from '../components/ProjectList.vue'
import WorkList from '../components/WorkList.vue'
import Chat from '../components/Chat.vue'
import WorkType1CList from '../components/WorkType1CList.vue'

const routes = [
  {
    path: '/',
    component: StartPage
  },
  {
    path: '/users',
    component: UserList
  },
  {
    path: '/tasks',
    component: TaskList
  },
  {
    path: '/clients',
    component: ClientList
  },
  {
    path: '/employees',
    component: EmployeeList
  },
  {
    path: '/taskReport',
    component: TaskReport
  },
  {
    path: '/ref/:ref',
    component: TaskList
  },
  {
    path: '/task/:taskId',
    component: TaskList
  },
  {
    path: '/projects',
    component: ProjectList
  },
  {
    path: '/work',
    component: WorkList
  },
  {
    path: '/chat',
    component: Chat
  },
  {
    path: '/workTypes1C',
    component: WorkType1CList
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
