<template>
  <!-- Фильтры -->
  <div v-if="showFilters">
    <!-- Фильтр по статусам -->
    <div class="d-flex justify-content-center">
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-light">
          <input type="radio" name="options" value="showAll" autocomplete="off" v-model="statusFilter" />
          Все
        </label>
        <label class="btn btn-light">
          <input type="radio" name="options" value="showOpened" autocomplete="off" v-model="statusFilter" />
          Открытые
        </label>
        <label class="btn btn-light">
          <input type="radio" name="options" value="showClosed" autocomplete="off" v-model="statusFilter" />
          Закрытые
        </label>
      </div>
    </div>

    <!-- Фильтр по неделе -->
    <div class="col" v-if="weekFilterVisibility">
      <div class="row mt-3 ms-3 me-3">
        <div class="col">
          <div class="row"><small class="text-muted">Тек. неделя</small></div>
          <div class="row">
            <div class="h5 text-success">{{ weekCur }}</div>
          </div>
        </div>
        <div class="col">
          <div class="row"><small class="text-muted">Неделя</small></div>
          <div class="row">
            <input v-model="weekFilter" class="h5" />
          </div>
        </div>
        <div class="col">
          <div class="row"><small class="text-muted">Приоритет</small></div>
          <div class="row">
            <input v-model="priorityFilter" class="h5" />
          </div>
        </div>
      </div>
    </div>

    <!-- Фильтр по исполнителям -->
    <div class="col">
      <div class="row mt-2 ms-3 me-3" v-if="!clientRef">

        <Multiselect v-model="selectedEmployee" :options="employeesJson" :loading="false" :internal-search="false"
          valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
          :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Исполнитель">
        </Multiselect>

      </div>
    </div>

    <!-- Фильтр по кураторам -->
    <div class="col">
      <div class="row mt-3 ms-3 me-3" v-if="!clientRef">
        <Multiselect v-model="selectedCurator" :options="employeesJson" :loading="false" :internal-search="false"
          valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
          :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Куратор">
        </Multiselect>
      </div>
    </div>

    <!-- Фильтр по клиентам -->
    <div class="col">
      <div class="row mt-3 ms-3 me-3" v-if="!clientRef">
        <Multiselect v-model="selectedClient" :options="clientsJson" :loading="false" :internal-search="false"
          valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
          :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Организация">
        </Multiselect>

      </div>
    </div>

    <!-- Кнопки Очистить фильтры и Создать -->
    <div class="col">
      <div class="row mt-3 ms-3 me-3">
        <div class="btn-group">
          <button class="btn btn-outline-danger" @click="cleanFilters">
            Очистить фильтры
          </button>
          <button class="btn btn-outline-primary" @click="addTask" v-if="!(selectedClient == 'allClients')">
            Создать
          </button>
        </div>

        <div v-if="spinner" class="spinner-border text-primary"></div>
      </div>
    </div>
  </div>

  <!-- Список задач -->
  <div class="list-group ms-3 me-3" v-for="item in tasksJson" :key="item._id">
    <div class="
        list-group-item list-group-item-action
        border border-primary
        shadow
        mt-3
      " v-bind:class="{
        'gradient-green': currentWeek(item.week),
      }" v-if="taskVisiblity(item)" :id="item._id" @click="item.contactSearch = false">
      <div class="row">
        <div class="col">
          <a class="text-primary text-center h5" target="_blank" v-if="item.client" :href="getClientRef(item)">{{
          item.client.name
          }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row"><small class="text-muted">Дата</small></div>
          <div class="h5">
            {{ new Date(item.date).toLocaleDateString("ru-RU") }}
          </div>
        </div>
        <div class="col">
          <div class="row"><small class="text-muted">Номер</small></div>
          <div class="h5">{{ item.number }}</div>
        </div>
        <div class="col">
          <div class="row"><small class="text-muted">Неделя</small></div>
          <input v-model="item.week" @input="item.modified = true" class="mb-1 h5 border-0" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row"><small class="text-muted">Статус</small></div>
          <div class="row">
            <input v-model="item.taskStatus" @input="item.modified = true" class="mb-1 h5 border-0" />
          </div>
        </div>
        <div class="col">
          <div class="row"><small class="text-muted">Приоритет</small></div>
          <div class="row">
            <input v-model="item.taskPriotity" class="mb-1 ms-1 h5 border-0" @input="item.modified = true" />
          </div>
        </div>
        <div class="col">
          <div class="row"><small class="text-muted">Кол час</small></div>
          <div class="row">
            <input v-model="item.taskHours" class="mb-1 ms-2 h5 border-0" @input="item.modified = true" />
          </div>
        </div>
        <div class="col">
          <div class="row"><small class="text-muted">Закрыто</small></div>
          <div class="row">
            <input v-model="item.taskClosed" type="checkbox" class="ms-3 form-check-input"
              @click="onChangeTaskClosed(item)" />
          </div>
        </div>
      </div>

      <!-- Описание -->
      <div>
        <small class="text-muted">Описание</small>
        <textarea v-model="item.taskDescription" class="mb-1 form-control overflow-auto" style="height: 150px"
          @input="item.modified = true"></textarea>
      </div>

      <!-- Ссылки на файлы-->
      <div v-for="(link, index) in item.links" :key="index">
        <a :href=link target="_blank" style="word-wrap: break-word" v-if="!item.modified">{{link}}</a>
        <input v-model="item.links[index]" v-if="item.modified" class="ms-1" style="width: 70%"/>
        <button type="button" class="ms-1 btn btn-outline-danger btn-sm" @click="removeFileFromTask(item,index)" style="width: 25%">
          Удалить
        </button>
      </div>

      <!-- Добавление файлов -->
      <input type="file" :id="'uploadFileToTaskButton'+item._id" style="display: none"
        @change="uploadFileToTask(item)" />
      <div class="btn-group m-1">
        <button type="button" class="btn btn-outline-primary btn-sm" @click="clickFileUpload(item)">
          Добавить файл
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm" @click="addLink(item)">
          Добавить ссылку
        </button>
        <button v-if="item.modified" class="btn btn-warning" @click="saveTask(item)">
          Сохранить
        </button>
      </div>

      <div v-if="item.spinner" class="spinner-border text-primary"></div>

      <div v-if="item.client._id in projectsJson" class="row">
        <div class="col">
          <div class="row" v-if="item.project">
            <a :href="item.project.link" target="_blank"><small class="text-muted">Проект</small></a>
          </div>
          <div class="row" v-if="!item.project">
            <small class="text-muted">Проект</small>
          </div>

          <div class="row" :class="{ 'border border-danger': item.projectError }">
            <select v-model="item.project" class="mb-1 h5 border-0" @input="item.modified = true">
              <option v-for="project in projectsJson[item.client._id]" :value="project" :key="project._id">
                {{ project.label }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="row">
            <small class="text-muted">Контактное лицо</small>
          </div>
          <div class="row">
            <input type="search" v-model="item.taskContact" class="mb-1 h5 border-0" @input="contactSearch(item)" />
            <div class="dropdown">
              <ul :class="{ show: item.contactSearch }" class="dropdown-menu">
                <li v-for="(contact, index) in contacts" :key="index" @click="contactSearchSelected(item, contact)">
                  <div class="dropdown-item">
                    {{ contact }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <small class="text-muted">Исполнитель</small>
          </div>

          <div class="row">
            <Multiselect v-model="item.employee" :options="employeesJson" :loading="false" :internal-search="false"
              valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
              :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Исполнитель"
              class="mb-1 h5 border-0" @input="item.modified = true">
            </Multiselect>

            <!-- <input
              v-model="item.taskEmployee"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            /> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row"><small class="text-muted">Куратор</small></div>
          <div class="row">

            <Multiselect v-model="item.curator" :options="employeesJson" :loading="false" :internal-search="false"
              valueProp="_id" trackBy="name" label="name" :filterResults="true" :minChars="2" :resolveOnLoad="true"
              :searchable="true" style="min-height: 3rem" noOptionsText="Не найден" placeholder="Исполнитель"
              class="mb-1 h5 border-0" @input="item.modified = true">
            </Multiselect>

            <!-- <input
              v-model="item.curator"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            /> -->
          </div>
        </div>
        <div class="row">
          <div class="col">
            <small class="text-muted">
              {{ getTaskRef(item) }}
            </small>
          </div>
        </div>
      </div>
      <button v-if="item.modified" class="btn btn-warning" @click="saveTask(item)">
        Сохранить
      </button>
      <button v-if="!(selectedClient == 'allClients')" class="btn btn-outline-primary ms-3" @click="copyTask(item)">
        Копировать
      </button>
      <button v-if="!(selectedClient == 'allClients')" class="btn btn-outline-danger ms-3" @click="deleteTask(item)">
        Удалить
      </button>
      <div v-if="spinner" class="spinner-border text-primary"></div>
    </div>
  </div>

  <!-- Button trigger modal -->
  <button id="modalButton" type="button" class="btn btn-primary" data-bs-toggle="modal"
    data-bs-target="#modalNeedDescription" style="display: none">
    modalButton
  </button>

  <!-- Modal -->
  <div class="modal fade" id="modalNeedDescription" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ modalTitle }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <textarea v-model="modalDescription" class="mb-1 form-control overflow-auto" style="height: 150px"></textarea>
        </div>
        <div class="modal-footer">
          <!-- fileLink -->
          <div class="row">
            <a v-if="!uploadFileButton" :href="fileLink" target="_blank"><small class="text-muted"
                style="word-wrap: break-word">{{
                fileLink
                }}</small></a>
          </div>
          <!-- buttons -->
          <div class="row">
            <input type="file" id="selectedFile" style="display: none" @change="sendFileFormData()" />
            <div class="btn-group">
              <button v-if="uploadFileButton" type="button" class="btn btn-success" @click="uploadFile()">
                Загрузить файл
              </button>
              <button v-if="saveDescriptionButton" type="button" class="btn btn-warning"
                @click="saveDescription(curTaskId, modalDescription)">
                Сохранить
              </button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    class="btn btn-outline-success fixed_button"
    @click="loadTasks"
  >
    Обновить
  </button>


  <!-- <pre>
    {{ tasksJson }}
  </pre> -->
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted, watch } from "vue";
import { Capacitor } from "@capacitor/core";
import * as common from "./common.js";
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    Multiselect,
  },

  setup() {
    const route = useRoute();
    const tasksJson = ref([]);
    const clientsJson = ref([]);
    const employeesJson = ref([]);
    const clientRef = route.params.ref;
    const spinner = ref(false);
    const statusFilter = ref("showOpened");
    const selectedClient = ref("allClients");
    const selectedEmployee = ref("allEmployees");
    const selectedCurator = ref("allEmployees");
    const weekCur = ref(0);
    const weekFilter = ref("");
    const priorityFilter = ref("")
    const weekFilterVisibility = ref(true);
    const showFilters = ref(true);
    const isNativePlatform = ref(Capacitor.isNativePlatform());
    const projectsJson = ref([]);
    const contacts = ref([]);
    const modalTitle = ref("Заголовок модального окна");
    const modalDescription = ref("Описание в модальном окне.");
    const curTaskId = ref();
    const saveDescriptionButton = ref(false);
    const uploadFileButton = ref(false);
    const fileLink = ref("");

    watch(
      () => statusFilter.value,
      () => {
        console.log("watch status filter");
        if (
          statusFilter.value == "showAll" ||
          statusFilter.value == "showClosed"
        )
          loadTasks(true);
      }
    );

    watch(
      () => route.params,
      async () => {
        await initFilters();
        await loadTasks();
      }
    );

    const contactSearch = async (item) => {
      item.modified = true;
      if (item.taskContact.length > 4) {
        let resContacts = await common.getContacts(item.taskContact);
        contacts.value = [];
        for (const resItem of resContacts) {
          contacts.value.push(resItem.fio + "; " + resItem.phone);
        }
        item.contactSearch = true;
      } else {
        item.contactSearch = false;
      }
    };

    const contactSearchSelected = async (item, contact) => {
      item.taskContact = contact;
      item.contactSearch = false;
    };

    const loadTasks = async (loadClosed = false) => {
      console.log("loadTasks");
      let fetchRef = process.env.VUE_APP_API_URL + "/getOpenedTasks";
      if (loadClosed) fetchRef = process.env.VUE_APP_API_URL + "/getTasks";

      if (route.params.ref) {
        fetchRef =
          process.env.VUE_APP_API_URL + "/getTasks/" + route.params.ref;
      }

      if (localStorage.getItem("clientRef")) {
        clientsJson.value = [];
        clientsJson.value.push(JSON.parse(localStorage.getItem("clientJson")));
        selectedClient.value = JSON.parse(localStorage.getItem("clientJson"));
        fetchRef =
          process.env.VUE_APP_API_URL +
          "/getTasks/" +
          localStorage.getItem("clientRef");
      }

      if (route.params.taskId) {
        fetchRef =
          process.env.VUE_APP_API_URL + "/getTask/" + route.params.taskId;
      }

      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      // tasks
      try {
        let res = await fetch(fetchRef, {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        let resJson = await res.json();
        tasksJson.value = resJson;
      } catch (error) {
        console.log(error);
      }

      // clients
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/getClient", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        if (res.status == 200) {
          clientsJson.value = await res.json();
          clientsJson.value.unshift({ _id: 'allClients', name: 'Все' })
        }

        //console.log(clientsJson.value);
      } catch (err) {
        console.log(err);
      }

      // employees
      employeesJson.value = await common.loadEmployees();
    };

    const addTask = async () => {
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      let newTaskEmployee = selectedEmployee.value
      selectedEmployee.value != "allEmployees"
        ? selectedEmployee.value
        : undefined;

      let newTask = {
        client: selectedClient.value,
        employee: newTaskEmployee,
        curator:
          selectedCurator.value != "allEmployees"
            ? selectedCurator.value
            : newTaskEmployee,
        clientId: selectedClient.value,
        taskStatus: "Новое",
        week: weekFilter.value,
        taskHours: 0,
      };
      let res = await fetch(process.env.VUE_APP_API_URL + "/addTask", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(newTask),
      });

      const resJson = await res.json();
      if (resJson._id) {
        await loadTasks();
        const el = document.getElementById(resJson._id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
          el.classList.remove("gradient-blue");
          el.classList.add("gradient-red");
          spinner.value = false;
        }
      }
    };

    const saveTask = async (item) => {
      curTaskId.value = item._id;
      item.taskHours = item.taskHours.replace(",", ".");

      if (Number(item.taskHours) >= 2 && item.taskClosed) {
        console.log("Закрыта задача более 3 часов.");
        let version =
          item.taskPriotity.slice(2, 4) +
          "." +
          item.client.number +
          "." +
          item.number;
        if (!item.project) {
          item.projectError = true;
          console.log("Не указан проект");
          saveTaskHours(item)
          needDescription(item);
          item.taskClosed = false
          return;
        }
        console.log(
          'Поиск текста "' +
          version +
          '" в описании проекта ' +
          item.project.link
        );

        let projectHTML = await fetch(item.project.link);
        let projectText = await projectHTML.text();

        if (item.project.link.includes('https://confluence.cs-develop.ru/view/') || item.project.link.includes('https://develop.marco.az/view/')) {
          let articlePath = item.project.link
          articlePath = articlePath.replace('https://confluence.cs-develop.ru/view/', '')
          articlePath = articlePath.replace('https://develop.marco.az/view/', '')
          articlePath = articlePath.replace('/', '')
          if (articlePath.includes('#')) articlePath = articlePath.substring(0, articlePath.indexOf('#'))
          console.log(articlePath);
          let res = await fetch(`https://confluence.cs-develop.ru/getArticle/${articlePath}`)
          let resJson = await res.json()
          projectText = resJson.text
        }

        let position = projectText.search(version);
        if (position == -1) {
          item.projectError = true;
          console.log("Не найдено");
          saveTaskHours(item)
          needDescription(item);
          item.taskClosed = false
          return;
        } else {
          console.log("Найдено в строке " + position);
        }
      }

      spinner.value = true;
      let res = await fetch(process.env.VUE_APP_API_URL + "/saveTask", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(item),
      });
      let resJson = await res.json();
      if (resJson._id == item._id) {
        item.modified = false;
        spinner.value = false;

        // send push to employee
        let name = "";
        if (localStorage.getItem("login")) name = localStorage.getItem("login");
        if (localStorage.getItem("clientJson"))
          name = JSON.parse(localStorage.getItem("clientJson")).name;
        let pushMessage =
          name + " изменил задачу № " + item.number + " от " + item.date +
          ". " + item.taskStatus + ". " + item.taskDescription;
        const pushRef = "/task/" + item._id;
        sendPushToEmployee(
          item.employee,
          pushMessage,
          pushRef,
          item.client.name
        );
        if (item.employee._id != item.curator._id)
          sendPushToEmployee(
            item.curator,
            pushMessage,
            pushRef,
            item.client.name
          );

        // send push to client
        sendPushToClient(
          item.client,
          pushMessage,
          pushRef,
          "Ваша задача изменена!"
        );
      }
    };

    const saveTaskHours = async (task) => {
      spinner.value = true;
      let item = { _id: task._id, taskHours: task.taskHours }
      let res = await fetch(process.env.VUE_APP_API_URL + "/saveTaskHours", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(item),
      });
      let resJson = await res.json();
      if (resJson._id == task._id) {
        task.modified = false;
        spinner.value = false;
      }
    }

    const needDescription = async (item) => {
      saveDescriptionButton.value = true;
      uploadFileButton.value = true;
      fileLink.value = "";

      let version =
        item.taskPriotity.slice(2, 4) +
        "." +
        item.client.number +
        "." +
        item.number;
      modalTitle.value = "Не найдено описание версии " + version;

      modalDescription.value =
        "## Сделано " +
        item.taskPriotity.slice(0, 10) +
        " (" + version + ") \r\n";
      modalDescription.value += "* Описание работ.\r\n";


      let description = await getDescription(item);
      if (description) modalDescription.value = description.text;
      let modalButton = document.getElementById("modalButton");
      modalButton.click();
    };

    const getDescription = async (item) => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getDescription/" + item._id,
        {
          method: "GET",
          headers: {
            Authorization: "Basic " + btoa(login + ":" + password),
          },
        }
      );
      let description = await res.json();
      return description;
    };

    const saveDescription = async (taskId, text) => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      let res = await fetch(process.env.VUE_APP_API_URL + "/saveDescription", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          task: taskId,
          text: text,
        }),
      });
      let resJson = await res.json();
      if (resJson.task == taskId && resJson.text == text)
        saveDescriptionButton.value = false;
    };

    const uploadFile = async () => {
      document.getElementById("selectedFile").click();
    };

    const sendFileFormData = async () => {
      console.log(window.event.target.files[0]);

      let formData = new FormData();
      formData.append("filedata", window.event.target.files[0]);
      let res = await fetch("https://cs-develop.ru/upload", {
        method: "POST",
        body: formData,
      });

      if (res.status == 200) {
        let resText = await res.text();
        console.log(resText);
        uploadFileButton.value = false;
        fileLink.value = resText;
        modalDescription.value = modalDescription.value.replace(
          "link",
          fileLink.value
        );
      }
    };

    const clickFileUpload = async (item) => {
      console.log('clickFileUpload');
      let uploadFileToTaskButton = document.getElementById('uploadFileToTaskButton' + item._id)
      uploadFileToTaskButton.click();
    }

    const uploadFileToTask = async (item) => {
      console.log('uploadFileToTask');
      item.spinner = true
      console.log(window.event.target.files[0]);

      let formData = new FormData();
      formData.append("filedata", window.event.target.files[0]);
      let res = await fetch("https://cs-develop.ru/upload", {
        method: "POST",
        body: formData,
      });

      if (res.status == 200) {
        let resText = await res.text();
        item.links.push(resText)
        item.modified = true
        item.spinner = false
      }
    }

    const addLink = async (item) => {
      item.links.push('') 
      item.modified = true
    }

    const removeFileFromTask = async (item, index) => {
      console.log(item.links[index]);
      item.links.splice(index, 1)
      item.modified = true
    }

    const deleteTask = async (item) => {
      let result = confirm("Удалить задание " + item.number + "?");
      if (!result) {
        console.log("!result");
        return;
      }

      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(process.env.VUE_APP_API_URL + "/deleteTask", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(item),
      });
      let resJson = await res.json();
      console.log(resJson);
      if (resJson._id) {
        await loadTasks();
        spinner.value = false;
      }
    };

    const copyTask = async (item) => {
      item.copied = true;
      spinner.value = true;

      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      const res = await fetch(process.env.VUE_APP_API_URL + "/copyTask", {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(login + ":" + password),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(item),
      });
      const resJson = await res.json();
      if (resJson._id) {
        await loadTasks();
        const el = document.getElementById(resJson._id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
          el.classList.remove("gradient-blue");
          el.classList.add("gradient-red");
          spinner.value = false;
        }
      }
    };

    const taskVisiblity = (item) => {
      if (item.modified) return true;

      // Фильтр по статусам
      if (statusFilter.value == "showOpened" && item.taskClosed) return false;
      if (statusFilter.value == "showClosed" && !item.taskClosed) return false;

      // Фильтр по клиентам
      if (selectedClient.value != "allClients") {
        if (item.client) {
          if (item.client._id != selectedClient.value) return false;
        } else {
          return false;
        }
      }
      // Фильтр по исполнителю
      if (selectedEmployee.value != "allEmployees") {
        if (item.employee) {
          if (item.employee != selectedEmployee.value) return false;
        } else {
          return false;
        }
      }

      // Фильтр по куратору
      if (selectedCurator.value != "allEmployees") {
        if (item.curator) {
          if (item.curator != selectedCurator.value) return false;
        } else {
          return false;
        }
      }

      // Фильтр по неделе
      if ((weekFilter.value != "") & (item.week != weekFilter.value))
        return false;

      // Фильтр по приоритету
      if ((priorityFilter.value != "") & (item.taskPriotity != priorityFilter.value))
        return false;

      return true;
    };

    const getClientRef = (item) => {
      return "/ref/" + item.client.clientRef;
    };

    const getTaskRef = (item) => {
      if (isNativePlatform.value) {
        return process.env.VUE_APP_API_URL + "/task/" + item._id;
      }
      return window.location.origin + "/task/" + item._id;
    };

    const onChangeTaskClosed = (item) => {
      let t = new Date();
      let z = t.getTimezoneOffset() * 60 * 1000;
      let tLocal = t - z;
      tLocal = new Date(tLocal);
      let iso = tLocal.toISOString();
      iso = iso.slice(0, 19);
      iso = iso.replace("T", " ");

      item.taskPriotity = iso;
      item.modified = true;
    };

    const currentWeek = (week) => {
      if (weekCur.value == Number(week)) return true;
      return false;
    };

    const sendPushToEmployee = async (
      employee,
      pushMessage,
      pushRef,
      title
    ) => {
      console.log("/sendPushToEmployee");

      let employeeObj = employee
      if (typeof (employee) != 'object') {
        let employeeIndex = employeesJson.value.findIndex(item => item._id == employee)
        employeeObj = employeesJson.value[employeeIndex]
      }

      let message = {
        title: title,
        employee: employeeObj,
        pushMessage: pushMessage,
        pushRef: pushRef,
      };
      let res = await fetch(process.env.VUE_APP_API_URL + "/sendPush", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(message),
      });
      console.log(res.status);
    };

    const sendPushToClient = async (client, pushMessage, pushRef, title) => {
      console.log("/sendPushToClient");
      let message = {
        title: title,
        client: client,
        pushMessage: pushMessage,
        pushRef: pushRef,
      };
      let res = await fetch(process.env.VUE_APP_API_URL + "/sendPushToClient", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(message),
      });
      console.log(res.status);
    };

    const cleanFilters = () => {
      statusFilter.value = "showOpened";
      selectedClient.value = "allClients";
      selectedEmployee.value = "allEmployees";
      selectedCurator.value = "allEmployees";
      weekFilter.value = "";
    };

    const initFilters = async () => {
      let curEmployee = await common.getCurEmployee();
      // console.log(curEmployee);
      if (route.params.ref || route.params.taskId) {
        statusFilter.value = "showAll";
        showFilters.value = false;
      } else {
        statusFilter.value = "showOpened";
        showFilters.value = true;

        if (curEmployee) selectedEmployee.value = curEmployee._id;
      }
    };

    const loadProjects = async () => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/project", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        let projects = await res.json();
        projectsJson.value = projects.reduce((r, a) => {
          r[a.client] = [...(r[a.client] || []), a];
          return r;
        }, {});
      } catch (err) {
        console.log(err);
      }
    };

    onMounted(async () => {
      console.log("onMounted");

      // Расчет тек недели
      weekCur.value = await common.getCurWeek();

      await initFilters();

      await loadTasks();
      await loadProjects();
    });

    return {
      loadTasks,
      tasksJson,
      saveTask,
      copyTask,
      statusFilter,
      taskVisiblity,
      getClientRef,
      getTaskRef,
      clientsJson,
      employeesJson,
      selectedClient,
      clientRef,
      spinner,
      addTask,
      deleteTask,
      onChangeTaskClosed,
      selectedEmployee,
      selectedCurator,
      currentWeek,
      weekFilter,
      priorityFilter,
      weekCur,
      weekFilterVisibility,
      showFilters,
      cleanFilters,
      projectsJson,
      contactSearch,
      contactSearchSelected,
      contacts,
      modalTitle,
      modalDescription,
      saveDescriptionButton,
      curTaskId,
      saveDescription,
      uploadFile,
      sendFileFormData,
      uploadFileButton,
      fileLink,
      clickFileUpload,
      uploadFileToTask,
      removeFileFromTask,
      addLink
    };
  },
};
</script>

<style>
.gradient-blue {
  background: linear-gradient(90deg,
      #a8ffee38,
      #ffffff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-red {
  background: linear-gradient(90deg,
      #ffa8af6e,
      #ffffff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-green {
  background: linear-gradient(90deg,
      #a8ffb494,
      #ffffff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.fixed_button {
  position: fixed;
  /*задаём тип позиции, в нашем случае - фиксированная*/
  bottom: 50px;
  right: 50px;
  z-index: 999;
  background-color: transparent;
}

.scrollable {
  height: 500px;
  overflow-y: scroll;
}
</style>