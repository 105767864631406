<template>
  <a href="/">
    <div class="word text-secondary">
      <span class="active text-primary">C</span>
      <span class="active text-primary">O</span>
      <span class="active text-primary">M</span>
      <span class="active text-primary">P</span>
       
      <span class="active text-danger">S</span>
      <span class="active text-danger">E</span>
      <span class="active text-danger">R</span>
      <span class="active text-danger">V</span>
      <span class="active text-danger">I</span>
      <span class="active text-danger">C</span>
      <span class="active text-danger">E</span>
    </div>
  </a>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const test = ref("test");

return {
      test,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Anton|Roboto");

.word {
  font-family: "Anton", sans-serif;
  perspective: 1000px;
}

.word span {
  cursor: pointer;
  display: inline-block;
  font-size: 50px;
  user-select: none;
  line-height: 0.8;
}

.word span:nth-child(1).active {
  animation: balance 1.5s ease-out;
  transform-origin: bottom left;
}

@keyframes balance {
  0%,
  100% {
    transform: rotate(0deg);
  }

  30%,
  60% {
    transform: rotate(-45deg);
  }
}

.word span:nth-child(2).active {
  animation: shrinkjump 1s ease-in-out;
  transform-origin: bottom center;
}

@keyframes shrinkjump {
  10%,
  35% {
    transform: scale(2, 0.2) translate(0, 0);
  }

  45%,
  50% {
    transform: scale(1) translate(0, -150px);
  }

  80% {
    transform: scale(1) translate(0, 0);
  }
}

.word span:nth-child(3).active {
  animation: falling 2s ease-out;
  transform-origin: bottom center;
}

@keyframes falling {
  12% {
    transform: rotateX(240deg);
  }

  24% {
    transform: rotateX(150deg);
  }

  36% {
    transform: rotateX(200deg);
  }

  48% {
    transform: rotateX(175deg);
  }

  60%,
  85% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.word span:nth-child(4).active {
  animation: rotate 1s ease-out;
}

@keyframes rotate {
  20%,
  80% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.word span:nth-child(5).active {
  animation: toplong 1.5s linear;
}

@keyframes toplong {
  10%,
  40% {
    transform: translateY(-48vh) scaleY(1);
  }

  90% {
    transform: translateY(-48vh) scaleY(4);
  }
}

.word span:nth-child(6).active {
  animation: balance2 1.5s ease-out;
  transform-origin: bottom left;
}

@keyframes balance2 {
  0%,
  100% {
    transform: rotate(20deg);
  }

  30%,
  60% {
    transform: rotate(0deg);
  }
}

.word span:nth-child(7).active {
  animation: shrinkjump2 2s ease-in-out;
  transform-origin: bottom center;
}

@keyframes shrinkjump2 {
  10%,
  35% {
    transform: scale(1, 0.2) translate(0, 0);
  }

  45%,
  50% {
    transform: scale(2) translate(0, 100px);
  }

  80% {
    transform: scale(1) translate(0, 0);
  }
}

.word span:nth-child(8).active {
  animation: falling2 2s ease-out;
  transform-origin: bottom center;
}

@keyframes falling2 {
  12% {
    transform: rotateY(240deg);
  }

  24% {
    transform: rotateY(150deg);
  }

  36% {
    transform: rotateY(200deg);
  }

  48% {
    transform: rotateY(175deg);
  }

  60%,
  85% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

.word span:nth-child(9).active {
  animation: rotate2 1s ease-out;
}

@keyframes rotate2 {
  20%,
  80% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.word span:nth-child(10).active {
  animation: toplong2 2s linear;
}

@keyframes toplong2 {
  10%,
  40% {
    transform: translateY(48vh) scaleY(1);
  }

  90% {
    transform: translateY(-48vh) scaleY(2);
  }
}

.word span:nth-child(11).active {
  animation: balance3 1.5s ease-out;
  transform-origin: bottom left;
}

@keyframes balance3 {
  0%,
  100% {
    transform: rotate(0deg);
  }

  30%,
  60% {
    transform: rotate(45deg);
  }
}
</style>