<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <h3 class="text-danger"><del>ENTERPRISE</del> уже не тот</h3>
        <!-- <img
          class="img-fluid"
          src="https://pcs.ru/tpl/i/logo-new.png"
          alt=""
          width="250"
        /> -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!-- <li class="nav-link" v-if="authenticated">
            <router-link to="/scrum">Scrum</router-link>
          </li> -->
          <li class="nav-item dropdown" v-if="authenticated">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Справочники
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/clients"
                  >Клиенты</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/employees"
                  >Сотрудники</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/users"
                  >Пользователи</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/workTypes1C"
                  >Виды работ 1С</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/projects"
                  >Проекты</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="authenticated || clientAccess">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Документы
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/tasks"
                  >Задачи</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/work"
                  >Работа</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="authenticated">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Отчеты
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/taskReport"
                  >Отчет по работам</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ getProfile() }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <div class="dropdown-item">
                  <div class="row">
                    <div v-if="!(authenticated || clientAccess)">
                      <div class="input-group-prepend">
                        <input
                          v-model="login"
                          type="text"
                          class="form-control"
                          placeholder="Логин"
                        />
                        <input
                          v-model="password"
                          type="password"
                          class="form-control"
                          placeholder="Пароль"
                        />
                        или
                      </div>

                      <input
                        v-model="clientRef"
                        type="text"
                        class="form-control"
                        placeholder="Код доступа"
                      />
                      <button
                        @click="storeAuthData"
                        class="btn btn-outline-primary"
                        type="button"
                      >
                        Войти
                      </button>
                    </div>
                    <div class="input-group-prepend">
                      <div v-if="authenticated">
                        Вход осуществлен через логин и пароль
                      </div>
                      <div v-if="clientAccess">
                        Вход осуществлен через код доступа
                      </div>
                      <button
                        @click="deleteAuthData"
                        class="btn btn-outline-danger"
                        type="button"
                      >
                        Выйти
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <!-- <form class="d-flex">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Поиск"
            aria-label="Search"
          />
          <button class="btn btn-outline-success" type="submit">Поиск</button>
        </form> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup() {
    //test3
    const login = ref("");
    const password = ref("");
    const clientRef = ref("");
    const authenticated = ref(false);
    const clientAccess = ref(false);

    async function isAuthenticated() {
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/checkAuth", {
          method: "GET",
          headers: {
            Authorization: "Basic " + btoa(login.value + ":" + password.value),
          },
        });
        let resText = await res.text();
        if (resText == "ok") return true;
        return false;
      } catch (err) {
        console.log(err);
        return false;
      }
    }

    async function isClientAccess() {
      console.log("checkClientRef");
      let body = { clientRef: clientRef.value };
      try {
        const res = await fetch(
          process.env.VUE_APP_API_URL + "/checkClientRef",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(body),
          }
        );
        console.log(res.status);
        if (res.status == 200) {
          let clientJson = await res.json();
          localStorage.setItem("clientJson", JSON.stringify(clientJson));
          return true;
        }
      } catch (err) {
        console.log(err);
      }
      return false;
    }

    const loadAuthData = async () => {
      clientRef.value = localStorage.getItem("clientRef");
      clientAccess.value = await isClientAccess();
      if (clientAccess.value) {
        localStorage.removeItem("login");
        localStorage.removeItem("password");
      }

      login.value = localStorage.getItem("login");
      password.value = localStorage.getItem("password");
      authenticated.value = await isAuthenticated();
      if (authenticated.value) {
        localStorage.removeItem("clientRef");
        localStorage.removeItem("clientJson");
      }
    };

    const storeAuthData = async () => {
      localStorage.setItem("login", login.value);
      localStorage.setItem("password", password.value);
      localStorage.setItem("clientRef", clientRef.value);
      loadAuthData();
    };

    const deleteAuthData = async () => {
      localStorage.removeItem("login");
      localStorage.removeItem("password");
      localStorage.removeItem("clientRef");
      authenticated.value = false;
      clientAccess.value = false;
    };

    const getProfile = () => {
      if (clientAccess.value) {
        const clientJson = JSON.parse(localStorage.getItem("clientJson"));
        return clientJson.name;
      }
      if (authenticated.value) {
        return localStorage.getItem("login");
      }
      return "Вход";
    };

    onMounted(loadAuthData);

    return {
      login,
      password,
      clientRef,
      storeAuthData,
      authenticated,
      clientAccess,
      deleteAuthData,
      getProfile,
    };
  },
};
</script>