<template>
  <div class="row m-3">
    <div class="d-flex justify-content-center">
      <Animation />
    </div>
  </div>

  <div class="row m-3">
    <div class="col-lg shadow">
      <!-- openedClosedTasksPerCurWeek -->
      <PieChart
        v-if="openedClosedTasksPerCurWeek"
        ref="doughnutRef"
        :chartData="openedClosedTasksPerCurWeek.chartData"
        :options="openedClosedTasksPerCurWeek.options"
      />
    </div>

    <div class="col-lg shadow">
      <LineChart
        v-if="openedClosedTasksPerLastWeeks"
        ref="doughnutRef"
        :chartData="openedClosedTasksPerLastWeeks.chartData"
        :options="openedClosedTasksPerLastWeeks.options"
      />
    </div>
  </div>

  <div class="row m-3 shadow" v-if="employeeDeal">
    <LineChart
      ref="doughnutRef"
      :chartData="employeeDeal.chartData"
      :options="employeeDeal.options"
    />
    <div class="btn-group mb-3" role="group" aria-label="Basic example">
      <button
        type="button"
        class="btn btn-outline-primary"
        @click="getEmployeeDeal"
      >
        Месяц
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        @click="getEmployeeDealLastWeek"
      >
        Неделя
      </button>
    </div>
  </div>

  <div class="row m-3" v-if="openedTasksPerClient">
    <div class="col-lg shadow">
      <DoughnutChart
        v-if="openedTasksPerClient"
        ref="doughnutRef"
        :chartData="openedTasksPerClient.chartData"
        :options="openedTasksPerClient.options"
      />
      <div class="btn-group mb-3" role="group" aria-label="Basic example">
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="getOpenedTasksPerClient"
        >
          Все
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="getOpenedTasksPerClientOnlyCurWeek"
        >
          Только {{weekCur}} неделя
        </button>
      </div>
    </div>
    <div class="col-lg shadow">
      <DoughnutChart
        v-if="openedTasksPerEmployee"
        ref="doughnutRef"
        :chartData="openedTasksPerEmployee.chartData"
        :options="openedTasksPerEmployee.options"
      />
      <div class="btn-group mb-3" role="group" aria-label="Basic example">
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="getOpenedTasksPerEmployee"
        >
          Все
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="getOpenedTasksPerEmployeeOnlyCurWeek"
        >
          Только {{weekCur}} неделя
        </button>
      </div>
    </div>
  </div>

  <!-- <pre>
    {{ openedTasksPerClient }}
  </pre> -->
</template>

<script>
import * as common from "@/components/common.js";

import Animation from "@/components/Animation.vue";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

import { defineComponent, ref, onMounted } from "vue";
import { DoughnutChart, PieChart, LineChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

export default defineComponent({
  name: "Home",
  components: {
    PieChart,
    LineChart,
    DoughnutChart,
    Animation,
  },
  setup() {
    const openedClosedTasksPerCurWeek = ref();
    const openedClosedTasksPerLastWeeks = ref();
    const employeeDeal = ref();
    const openedTasksPerClient = ref();
    const openedTasksPerEmployee = ref();
    const weekCur = ref(0)

    const getPublicStatistics = async () => {
      console.log("getPublicStatistics");
      let fetchRef = process.env.VUE_APP_API_URL + "/getPublicStatistics";

      try {
        let res = await fetch(fetchRef, {
          method: "GET",
        });
        let publicStatistics = await res.json();
        openedClosedTasksPerCurWeek.value =
          publicStatistics.openedClosedTasksPerCurWeek;
        openedClosedTasksPerLastWeeks.value =
          publicStatistics.openedClosedTasksPerLastWeeks;
      } catch (error) {
        console.log(error);
      }
    };

    const getEmployeeDeal = async () => {
      console.log("getEmployeeDeal");
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(process.env.VUE_APP_API_URL + "/getEmployeeDeal", {
        method: "GET",
        headers: { Authorization: "Basic " + btoa(login + ":" + password) },
      });

      let resJson = await res.json();
      employeeDeal.value = resJson.employeeDeal;
      console.log();
    };

    const getEmployeeDealLastWeek = async () => {
      console.log("getEmployeeDealLastWeek");
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getEmployeeDealLastWeek",
        {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        }
      );

      let resJson = await res.json();
      employeeDeal.value = resJson.employeeDeal;
      console.log();
    };

    const getOpenedTasksPerClient = async () => {
      console.log("getOpenedTasksPerClient");
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getOpenedTasksPerClient/all",
        {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        }
      );
      let resJson = await res.json();
      openedTasksPerClient.value = resJson.openedTasksPerClient;
    };

    const getOpenedTasksPerClientOnlyCurWeek = async () => {
      console.log("getOpenedTasksPerClient");
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getOpenedTasksPerClient/onlyCurWeek",
        {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        }
      );
      let resJson = await res.json();
      openedTasksPerClient.value = resJson.openedTasksPerClient;
    };

    const getOpenedTasksPerEmployee = async () => {
      console.log("getOpenedTasksPerEmployee");
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getOpenedTasksPerEmployee/all",
        {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        }
      );

      let resJson = await res.json();
      openedTasksPerEmployee.value = resJson.openedTasksPerEmployee;
    };

    const getOpenedTasksPerEmployeeOnlyCurWeek = async () => {
      console.log("getOpenedTasksPerEmployee");
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getOpenedTasksPerEmployee/onlyCurWeek",
        {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        }
      );

      let resJson = await res.json();
      openedTasksPerEmployee.value = resJson.openedTasksPerEmployee;
    };

    onMounted(async () => {
      weekCur.value = await common.getCurWeek();

      await getPublicStatistics();
      await getEmployeeDealLastWeek();
      await getOpenedTasksPerClient();
      await getOpenedTasksPerEmployee();
    });

    return {
      openedClosedTasksPerCurWeek,
      openedClosedTasksPerLastWeeks,
      employeeDeal,
      getEmployeeDeal,
      getEmployeeDealLastWeek,
      openedTasksPerClient,
      openedTasksPerEmployee,
      getOpenedTasksPerClient,
      getOpenedTasksPerClientOnlyCurWeek,
      getOpenedTasksPerEmployee,
      getOpenedTasksPerEmployeeOnlyCurWeek,
      weekCur
    };
  },
});
</script>