<template>
  <div class="mt-3">
    <button class="btn btn-outline-primary" @click="addUser">Создать</button>
  </div>

  <div class="list-group" v-for="user in users" :key="user._id">
    <div
      :id="user._id"
      class="list-group-item list-group-item-action border border-primary mt-3"
    >
      <div class="row">
        <div class="col">
          <div class="row"><small class="text-muted">Id</small></div>
          <div class="row">
            <h5 class="mb-1 h5 border-0 text-muted">
              {{ user._id }}
            </h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row"><small class="text-muted">Имя</small></div>
          <div class="row">
            <input
              v-model="user.name"
              class="mb-1 h5 border-0"
              @input="user.modified = true"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row"><small class="text-muted">Username</small></div>
          <div class="row">
            <input
              v-model="user.username"
              class="mb-1 h5 border-0"
              @input="user.modified = true"
            />
          </div>
        </div>

        <div class="col">
          <div class="row">
            <small class="text-muted password">Password</small>
          </div>
          <div class="row">
            <input
              type="password"
              v-model="user.password"
              class="mb-1 h5 border-0"
              @input="user.modified = true"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row"><small class="text-muted">Token</small></div>
          <div class="row">
            <input
              v-model="user.token"
              class="mb-1 h5 border-0"
              @input="user.modified = true"
            />
          </div>
        </div>
      </div>

      <div class="btn-group mt-3">
        <button
          v-if="user.modified"
          class="btn btn-warning"
          @click="saveUser(user)"
        >
          Сохранить
        </button>

        <button class="btn btn-danger" @click="deleteUser(user)">
          Удалить
        </button>
      </div>

      <div v-if="user.spinner" class="spinner-border text-primary"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  setup() {
    let users = ref([]);
    const getUsers = async () => {
      console.log("/getUsers");
      let res = await fetch(process.env.VUE_APP_API_URL + "/getUsers", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("login") +
                ":" +
                localStorage.getItem("password")
            ),
        },
      });
      console.log(res.status);
      if (res.status == 200) {
        users.value = await res.json();
      }
    };
    const saveUser = async (user) => {
      user.spinner = true;
      console.log("/saveUser");
      let res = await fetch(process.env.VUE_APP_API_URL + "/saveUser", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("login") +
                ":" +
                localStorage.getItem("password")
            ),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(user),
      });
      console.log(res.status);
      let resJson = undefined;
      if (res.status == 200) {
        resJson = await res.json();
        console.log(resJson);
        user.spinner = false;
        user.modified = false;
      }
    };
    const addUser = async () => {
      console.log("/addUser");
      let newUser = {
        name: "Новый пользователь",
        username: "newuser",
        password: "newuser",
        admin: false,
      };
      let res = await fetch(process.env.VUE_APP_API_URL + "/addUser", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("login") +
                ":" +
                localStorage.getItem("password")
            ),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(newUser),
      });
      console.log(res.status);
      let resJson = undefined;
      if (res.status == 200) {
        await getUsers();
        resJson = await res.json();
        const el = document.getElementById(resJson._id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
          el.classList.add("bg-danger");
        }
      }
    };

    const deleteUser = async (user) => {
      let result = confirm("Удалить пользователя " + user._id + "?");
      if (!result) {
        console.log("!result");
        return;
      }
      user.spinner = true;
      console.log("/deleteUser");
      let res = await fetch(process.env.VUE_APP_API_URL + "/deleteUser", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("login") +
                ":" +
                localStorage.getItem("password")
            ),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(user),
      });
      console.log(res.status);
      if (res.status == 200) {
        await getUsers();
        user.spinner = false;
      }
    };
    onMounted(getUsers);
    return {
      users,
      saveUser,
      addUser,
      deleteUser,
    };
  },
};
</script>