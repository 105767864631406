<template>
  <div class="mt-3">
    <button class="btn btn-outline-primary" @click="addEmployee">
      Создать
    </button>
    <div v-if="spinner" class="spinner-border text-primary"></div>
  </div>

  <div class="list-group" v-for="item in employeesJson" :key="item._id">
    <div
      class="
        list-group-item list-group-item-action
        border border-primary
        mt-3
        gradient
      "
      :id="item._id"
    >
      <div class="row">
        <!-- ID -->
        <div class="col-sm">
          <div class="row"><small class="text-muted">Id</small></div>
          <div class="row">
            <h5 class="mb-1 h5 border-0 text-muted">
              {{ item._id }}
            </h5>
          </div>
        </div>

        <!-- Наименование -->
        <div class="col-sm">
          <div class="row"><small class="text-muted">Наименование</small></div>
          <div class="row">
            <input
              v-model="item.name"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Пользователь -->
        <div class="col-sm">
          <div class="row">
            <small class="text-muted">Пользователь</small>
          </div>
          <div class="row">
            <select
              v-model="item.user"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            >
              <option v-for="user in usersJson" :value="user" :key="user._id">
                {{ user.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- guid1C -->
        <div class="col-sm">
          <div class="row"><small class="text-muted">guid1C</small></div>
          <div class="row">
            <input
              v-model="item.guid1C"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            />
          </div>
        </div>
      </div>

      <button
        v-if="item.modified"
        class="btn btn-warning"
        @click="saveEmployee(item)"
      >
        Сохранить
      </button>
      <div v-if="spinner" class="spinner-border text-primary"></div>
    </div>
  </div>

  <!-- <pre>
    {{ clientsJson }}
  </pre> -->
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup() {
    let employeesJson = ref([]);
    let usersJson = ref([]);

    const spinner = ref(false);

    const getUsers = async () => {
      console.log("/getUsers");
      let res = await fetch(process.env.VUE_APP_API_URL + "/getUsers", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("login") +
                ":" +
                localStorage.getItem("password")
            ),
        },
      });
      console.log(res.status);
      if (res.status == 200) {
        usersJson.value = await res.json();
      }
    };

    const getEmployees = async () => {
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      try {
        let res = await fetch(
          process.env.VUE_APP_API_URL + "/getEmployeesPopulated",
          {
            method: "GET",
            headers: { Authorization: "Basic " + btoa(login + ":" + password) },
          }
        );
        employeesJson.value = await res.json();
      } catch (err) {
        console.log(err);
      }
    };

    const saveEmployee = async (item) => {
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      try {
        const res = await fetch(process.env.VUE_APP_API_URL + "/saveEmployee", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Basic " + btoa(login + ":" + password),
          },
          body: JSON.stringify(item),
        });
        const resJson = await res.json();
        if (resJson._id == item._id) {
          item.modified = false;
          spinner.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const addEmployee = async () => {
      spinner.value = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");
      let newClient = {
        name: "Новый",
      };
      console.log(newClient);
      try {
        const res = await fetch(process.env.VUE_APP_API_URL + "/addEmployee", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Basic " + btoa(login + ":" + password),
          },
          body: JSON.stringify(newClient),
        });
        const resJson = await res.json();
        if (resJson._id) {
          await getEmployees();
          const el = document.getElementById(resJson._id);
          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
            el.classList.remove("gradient-blue");
            el.classList.add("gradient-red");
            spinner.value = false;
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    onMounted(async () => {
      await getUsers();
      await getEmployees();
    });

    return {
      saveEmployee,
      employeesJson,
      usersJson,
      spinner,
      addEmployee,
      getUsers,
    };
  },
};
</script>

 <style>
.gradient {
  background: linear-gradient(
    90deg,
    #a8ffee38,
    #ffffff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-red {
  background: linear-gradient(
    90deg,
    #ffa8af38,
    #ffffff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>