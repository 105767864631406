<template>
  <!-- Настройка периодичности отчета -->
  <div class="col" v-if="weekFilterVisibility">
    <div class="row mt-2 ms-3 me-3">
      <select
        v-model="selectedPeriod"
        @change="settingsModified = true"
        class="form-select"
      >
        <option value="week" selected>Неделя</option>
        <option value="mounth" selected>Месяц</option>
      </select>
    </div>
  </div>

  <!-- Фильтр по неделе -->
  <div class="col" v-if="(selectedPeriod == 'week') & weekFilterVisibility">
    <div class="row mt-3 ms-3 me-3">
      <div class="col">
        <div class="row"><small class="text-muted">Тек. неделя</small></div>
        <div class="row">
          <div class="h5 text-success">{{ weekCur }}</div>
        </div>
      </div>
      <div class="col">
        <div class="row"><small class="text-muted">Неделя</small></div>
        <div class="row">
          <input
            v-model="weekFilter"
            @input="settingsModified = true"
            class="h5"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- curatorReport -->
  <div class="table-responsive" v-if="curatorReportVisibility">
    <table class="table table-re">
      <thead>
        <tr>
          <th scope="col">Куратор</th>
          <th scope="col" v-for="(item, index) in reportDates" :key="index">
            {{ item }}
          </th>
          <th scope="col">Часов открыто</th>
        </tr>
      </thead>
      <tbody v-for="(row, index) in curatorReport" :key="index">
        <tr>
          <td>
            {{ index }}
          </td>
          <td v-for="(item, index) in reportDates" :key="index">
            <div v-if="row[item]">
              <span class="text-success">{{ row[item].taskHoursClosed }} </span>
              / <span class="text-danger">{{ row[item].taskHoursOpened }}</span>
            </div>
          </td>
          <td>
            <span class="text-danger"> {{ row.taskHoursOpened }} </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- employeeReport -->
  <div class="table-responsive">
    <table class="table table-re">
      <thead>
        <tr>
          <th scope="col">Исполнитель</th>
          <th scope="col" v-for="(item, index) in reportDates" :key="index">
            {{ item }}
          </th>
          <th scope="col">Часов открыто</th>
        </tr>
      </thead>
      <tbody v-for="(row, index) in employeeReport" :key="index">
        <tr>
          <td>
            {{ index }}
          </td>
          <td v-for="(item, index) in reportDates" :key="index">
            <div v-if="row[item]">
              <span class="text-success">{{ row[item].taskHoursClosed }} </span>
              / <span class="text-danger">{{ row[item].taskHoursOpened }}</span>
            </div>
          </td>
          <td>
            <span class="text-danger"> {{ row.taskHoursOpened }} </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <button
    class="btn fixed_report_button transparent"
    @click="loadTasks"
    :class="{
      'btn-outline-danger': settingsModified,
      'btn-outline-primary': !settingsModified,
    }"
  >
    Обновить отчет
  </button>

  <!-- <pre>
    {{ tasksJson }}
  </pre> -->
</template>

<script>
import { ref, onMounted, watch } from "vue";
import * as common from "./common.js";

export default {
  props: {
    propsWeekFilter: String,
    propsCuratorReportVisibility: Boolean,
  },
  setup(props) {
    const tasksJson = ref([]);
    const clientsJson = ref([]);
    const employeesJson = ref([]);
    const curatorReport = ref([]);
    const employeeReport = ref([]);
    const reportDates = ref([]);
    const selectedPeriod = ref("week");
    const weekCur = ref(0);
    const weekFilter = ref("");
    const settingsModified = ref(false);
    const curatorReportVisibility = ref(true);
    const weekFilterVisibility = ref(true);

    watch(
      () => props.propsWeekFilter,
      () => {
        weekFilter.value = props.propsWeekFilter;
        loadTasks();
      }
    );

    const loadTasks = async () => {
      settingsModified.value = false;
      let fetchRef = process.env.VUE_APP_API_URL + "/getTasksForReport";

      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      // tasks
      try {
        let res = await fetch(fetchRef, {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        let resJson = await res.json();
        tasksJson.value = resJson;
      } catch (error) {
        console.log(error);
      }

      // clients
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/getClient", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        clientsJson.value = await res.json();
        //console.log(clientsJson.value);
      } catch (err) {
        console.log(err);
      }

      // employees
      try {
        let res = await fetch(process.env.VUE_APP_API_URL + "/getEmployees", {
          method: "GET",
          headers: { Authorization: "Basic " + btoa(login + ":" + password) },
        });
        employeesJson.value = await res.json();
      } catch (err) {
        console.log(err);
      }

      // report data
      reportDates.value = getReportDates();
      curatorReport.value = calculateReportData("curator");
      employeeReport.value = calculateReportData("employee");
    };

    const getRef = (item) => {
      return "/task/" + item._id;
    };

    function calculateReportData(field) {
      const reducer = (res, obj) => {
        let key = "No " + field;
        if (obj[field]) key = obj[field].name;

        let closeMonthYearString = "no date";
        if (obj.taskClosed) {
          let closeDate = new Date(obj.taskPriotity);
          let closeYear = String(closeDate.getFullYear());
          let closeMonth = String(closeDate.getMonth() + 1);
          let closeWeek = obj.week;
          if (closeWeek == undefined) closeWeek = '0'
          let closePeriod = closeMonth;
          if (selectedPeriod.value == "week") closePeriod = closeWeek;
          if (selectedPeriod.value == "month") closePeriod = closeMonth;
          if (closePeriod.length == 1) closePeriod = "0" + closePeriod;
          closeMonthYearString = closeYear + "." + closePeriod;
        }

        let openMonthYearString = "no date";
        let openDate = new Date(obj.date);
        let openYear = String(openDate.getFullYear());
        let openMonth = String(openDate.getMonth() + 1);
        let openWeek = obj.week;
        if (openWeek == undefined) openWeek = '0'
        let openPeriod = openMonth;
        if (selectedPeriod.value == "week") openPeriod = openWeek;
        if (selectedPeriod.value == "month") openPeriod = openMonth;
        if (openPeriod.length == 1) openPeriod = "0" + openPeriod;
        openMonthYearString = openYear + "." + openPeriod;

        if (!res[key]) {
          res[key] = {
            taskHoursOpened: 0,
          };
          res[key][closeMonthYearString] = {
            taskHoursOpened: 0,
            taskHoursClosed: 0,
          };
          res[key][openMonthYearString] = {
            taskHoursOpened: 0,
            taskHoursClosed: 0,
          };
        }

        if (!res[key][closeMonthYearString]) {
          res[key][closeMonthYearString] = {
            taskHoursOpened: 0,
            taskHoursClosed: 0,
          };
        }

        if (!res[key][openMonthYearString]) {
          res[key][openMonthYearString] = {
            taskHoursOpened: 0,
            taskHoursClosed: 0,
          };
        }

        let taskHours = Number(obj.taskHours);
        if (Number.isNaN(taskHours)) {
          return res;
        }

        res[key][openMonthYearString].taskHoursOpened += taskHours;

        if (!obj.taskClosed) {
          res[key].taskHoursOpened += taskHours;
        }

        if (obj.taskClosed) {
          res[key][closeMonthYearString].taskHoursClosed += taskHours;
        }

        return res;
      };
      const initialValue = {};
      return tasksJson.value.reduce(reducer, initialValue);
    }

    function getReportDates() {
      console.log("getReportDates");
      const reducer = (res, obj) => {
        if (!res) res = [];

        if (selectedPeriod.value == "week") {
          if (obj.week == undefined || obj.week == "") return res;
          if ((weekFilter.value != "") & (weekFilter.value != obj.week))
            return res;
        }

        if (obj.taskClosed) {
          let closeDate = new Date(obj.taskPriotity);
          let closeYear = String(closeDate.getFullYear());
          let closeMonth = String(closeDate.getMonth() + 1);
          let closeWeek = obj.week;
          let closePeriod = closeMonth;
          if (selectedPeriod.value == "week") closePeriod = closeWeek;
          if (selectedPeriod.value == "month") closePeriod = closeMonth;
          if (closePeriod.length == 1) closePeriod = "0" + closePeriod;
          let closeMonthYearString = closeYear + "." + closePeriod;

          let foundCloseDate = false;
          res.forEach((element) => {
            if (element == closeMonthYearString) foundCloseDate = true;
          });
          if (!foundCloseDate) {
            res.push(closeMonthYearString);
          }
          //console.log(closeMonthYearString);
        }

        //openDate
        let openDate = new Date(obj.date);
        let openYear = String(openDate.getFullYear());
        let openMonth = String(openDate.getMonth() + 1);
        let openWeek = obj.week;
        let openPeriod = openMonth;
        if (selectedPeriod.value == "week") openPeriod = openWeek;
        if (selectedPeriod.value == "month") openPeriod = openMonth;
        if (openPeriod.length == 1) openPeriod = "0" + openPeriod;
        let openMonthYearString = openYear + "." + openPeriod;

        let foundOpenDate = false;
        res.forEach((element) => {
          if (element == openMonthYearString) foundOpenDate = true;
        });
        if (!foundOpenDate) {
          res.push(openMonthYearString);
        }

        return res;
      };

      const initialValue = [];
      let dates = tasksJson.value.reduce(reducer, initialValue);

      dates.sort(function (a, b) {
        return a - b;
      });

      return dates;
    }

    onMounted(async () => {
      console.log("onMounted");
      if (props.propsCuratorReportVisibility != undefined)
        curatorReportVisibility.value = props.propsCuratorReportVisibility;

      if (props.propsWeekFilter != undefined)
        weekFilterVisibility.value = false;

      weekCur.value = await common.getCurWeek();
      curatorReportVisibility.value = true;
      loadTasks();
    });

    return {
      tasksJson,
      clientsJson,
      employeesJson,
      getRef,
      loadTasks,
      curatorReport,
      employeeReport,
      reportDates,
      selectedPeriod,
      weekCur,
      weekFilter,
      settingsModified,
      curatorReportVisibility,
      weekFilterVisibility,
    };
  },
};
</script>

<style>
.fixed_report_button {
  position: fixed; /*задаём тип позиции, в нашем случае - фиксированная*/
  bottom: 100px;
  right: 50px;
  z-index: 999;
}
.transparent {
  background-color: transparent;
}
</style>