<template>
  <div class="list-group" v-for="item in workTypes1CJson" :key="item._id">
    <div
      class="
        list-group-item list-group-item-action
        border border-primary
        mt-3
        gradient
      "
      :id="item._id"
    >
      <div class="row">
        <!-- ID -->
        <div class="col-sm">
          <div class="row"><small class="text-muted">Id</small></div>
          <div class="row">
            <h5 class="mb-1 h5 border-0 text-muted">
              {{ item._id }}
            </h5>
          </div>
        </div>

        <!-- Наименование -->
        <div class="col-sm">
          <div class="row"><small class="text-muted">Наименование</small></div>
          <div class="row">
            <input
              v-model="item.name"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            />
          </div>
        </div>
      </div>

      <div class="row">

        <!-- guid1C -->
        <div class="col-sm">
          <div class="row"><small class="text-muted">guid1C</small></div>
          <div class="row">
            <input
              v-model="item.guid1C"
              class="mb-1 h5 border-0"
              @input="item.modified = true"
            />
          </div>
        </div>
      </div>

      <button
        v-if="item.modified"
        class="btn btn-warning"
        @click="saveWorkType1C(item)"
      >
        Сохранить
      </button>
      <div v-if="item.spinner" class="spinner-border text-primary"></div>
    </div>
  </div>

  <!-- <pre>
    {{ clientsJson }}
  </pre> -->
</template>

<script>
import { ref, onMounted } from "vue";
import * as common from "./common.js";

export default {
  setup() {
    let workTypes1CJson = ref([]);

    const saveWorkType1C = async (item) => {
      item.spinner = true;
      let login = localStorage.getItem("login");
      let password = localStorage.getItem("password");

      try {
        const res = await fetch(process.env.VUE_APP_API_URL + "/saveWorkType1C", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Basic " + btoa(login + ":" + password),
          },
          body: JSON.stringify(item),
        });
        const resJson = await res.json();
        if (resJson._id == item._id) {
          item.modified = false;
          item.spinner = false;
        }
      } catch (err) {
        console.log(err);
      }
    };

    onMounted(async () => {
      workTypes1CJson.value = await common.getWorkTypes1C();
    });

    return {
      workTypes1CJson,
      saveWorkType1C,
    };
  },
};
</script>
